.HaulRoadCondition__DisplayedShiftLabel {
    font-size: 0.85rem;
}

.HaulRoadCondition__PlotOptionLow {
    display: flex;
    align-content: center;
    justify-content: center;
}

.HaulRoadCondition__PlotOptionLowIcon {
    margin-left: 0.5rem;
    color: blue;
}

.HaulRoadCondition__PlotOptionMediumIcon {
    margin-left: 0.5rem;
    color: yellow;
}

.HaulRoadCondition__PlotOptionHighIcon {
    margin-left: 0.5rem;
    color: red;
}

// Temporary styles to override the conflicts between foundation & ant design
.leaflet-control-layers-overlays hr {
    margin: 6px;
}

.leaflet-control-layers-selector.leaflet-control-layers-selector {
    margin-bottom: 0;
}

.MinesiteMap__Legend__Trigger {
    margin-bottom: 0;
}

.HaulRoadConditionChart {
    // Push the chart down a bit from the Last Shift KPI thing
    margin-top: 20px;

    .PercentageAreaChart-series,
    .PercentageAreaChart-series path {
        &.highcharts-color-0 {
            .color-series-override(@surface-roughness-color-highest);
        }
        &.highcharts-color-1 {
            .color-series-override(@surface-roughness-color-high);
        }
        &.highcharts-color-2 {
            .color-series-override(@surface-roughness-color-medium);
        }
        &.highcharts-color-3 {
            .color-series-override(@surface-roughness-color-low);
        }
        &.highcharts-color-4 {
            .color-series-override(@surface-roughness-color-lowest);
        }
    }

    .PercentageAreaChart-series path {
        // Highlight the hovered points using our theme colour
        &.highcharts-point-hover {
            .color-series-override(@color-maxmine-primary-light);
        }

        // Highlight selected points using our theme colour
        &.highcharts-point-select {
            .color-series-override(@color-maxmine-primary-dark);
        }
    }
}
