.ConformanceLegend {
    display: flex;
    margin: 0.5rem 0 0.5rem 0;
}

.ConformanceLegend__Row {
    display: flex;
    margin-right: 0.5rem;
}

.ConformanceLegend__Icon {
    height: 20px;
    width: 20px;
    margin-right: 0.5rem;
}

@media print {
    .ConformanceLegend {
        margin-left: 15px;
    }
}
