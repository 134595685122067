.Fullscreen {
    .FeatureMessage {
        display: none;
    }
    .Layout__SubHeader {
        display: none;
    }
}

.Layout {
    & .Header__Logo--Container {
        height: 55px;
    }
}
