// Hide the point markers...
.highcharts__point--hidden {
    visibility: hidden;

    // ..but show them when hovering, selecting or if there is only one point
    &.highcharts__point--single,
    &.highcharts-point-hover,
    &.highcharts-point-select {
        visibility: initial;
    }
}

// Mixin to override highcharts colors
.color-series-override(@color) {
    fill: @color;
    stroke: @color;
}

// Use our contrasting colours for graph series
.highcharts-color-0 {
    .color-series-override(@color-display-7-1);
}
.highcharts-color-1 {
    .color-series-override(@color-display-7-2);
}
.highcharts-color-2 {
    .color-series-override(@color-display-7-3);
}
.highcharts-color-3 {
    .color-series-override(@color-display-7-4);
}
.highcharts-color-4 {
    .color-series-override(@color-display-7-5);
}
.highcharts-color-5 {
    .color-series-override(@color-display-7-6);
}
.highcharts-color-6 {
    .color-series-override(@color-display-7-7);
}

// Colours for special meaning series
.FalconChart__series {
    &--best-practice {
        &,
        & .highcharts-point {
            .color-series-override(@color-chart-best-practice);
        }
    }

    &--top-five {
        &,
        & .highcharts-point {
            .color-series-override(@color-chart-top-five);
        }
    }

    &--bottom-five {
        &,
        & .highcharts-point {
            .color-series-override(@color-chart-bottom-five);
        }
    }
}

// Map the colours to the tooltips
.FalconChart__tooltip-series {
    &--0 {
        color: @color-display-7-1;
    }
    &--1 {
        color: @color-display-7-2;
    }
    &--2 {
        color: @color-display-7-3;
    }
    &--3 {
        color: @color-display-7-4;
    }
    &--4 {
        color: @color-display-7-5;
    }
    &--best-practice {
        color: @color-chart-best-practice;
    }
    &--top-five {
        color: @color-chart-top-five;
    }
    &--bottom-five {
        color: @color-chart-bottom-five;
    }
}

// Hide the tooltip box if displaying in the top right of the chart
.FalconChart__chart--tooltip {
    &-topright .highcharts-label-box.highcharts-tooltip-box {
        visibility: hidden;
    }
}

.FalconChart__tooltip {
    // Use a fixed width font for the tooltip values
    & b {
        font-family: 'Forno MX', serif;
    }

    // Align the tooltip text so it doesn't jump around on hover
    &--topright {
        float: right;
        text-align: right;
        width: 0px;

        & table {
            float: right;
        }
    }
}

.highcharts-container {
    // Override text styles
    font-family: @font-family;

    &,
    & .highcharts-root {
        // Don't clip content outside the chart
        // We use this to display the legend at the top for example
        overflow: visible;
    }
}

// Adjust the styling on tooltips
.highcharts-tooltip-box {
    stroke: @color-text-light;
    fill-opacity: 1;
}

// Give a bit of a smooth transition on select why not
.highcharts-point-select {
    .transition-in-mixin();
}

.FalconChart__chart {
    & .highcharts-label.highcharts-tooltip,
    .highcharts-tooltip {
        // Remove the blurryness from the tooltips
        filter: none;
    }

    & .highcharts-yaxis .highcharts-tick,
    & .highcharts-yaxis .highcharts-axis-line {
        // Show the Y axis line and ticks
        stroke-width: 1;
    }
}
