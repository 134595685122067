.DateTimePicker {
    // Styles to fix the wonky panel displays
    & .react-datepicker {
        font-family: inherit;
        border-radius: 0;
    }
    & .react-datepicker-wrapper {
        width: 170px;
    }
    & .react-datepicker__time-container--with-today-button {
        border-radius: 0;
        right: -85px;
        top: -1px;
    }
    & .react-datepicker__month-container {
        margin-bottom: 1px;
    }
    & .react-datepicker__header {
        border-radius: 0;
    }

    // Hide the regular header and just show the dropdowns
    & .react-datepicker__current-month {
        display: none;
    }

    // Make sure it displays on top of things like maps
    & .react-datepicker-popper {
        z-index: 999;
    }

    // Style like antd input
    & input {
        box-sizing: border-box;
        margin: 0;
        font-variant: tabular-nums;
        list-style: none;
        font-feature-settings: 'tnum';
        position: relative;
        display: inline-block;
        width: 100%;
        height: 32px;
        padding: 4px 11px;
        color: rgba(0, 0, 0, 0.65);
        font-size: 14px;
        line-height: 32px;
        background-color: #fff;
        background-image: none;
        border: 1px solid #d9d9d9;
        border-radius: 0;
        transition: all 0.3s;
        outline: none;
    }
    & select {
        outline: none;
    }
}
