.HelpPage__IFrame {
    position: fixed;
    top: 50px;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@media screen and (min-width: 1000px) {
    .HelpPage__IFrame {
        top: 100px;
    }
}
