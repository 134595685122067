.time-slider {
    display: flex;
    flex: 1;
}

.time-slider .slider {
    flex: 1;
}

.time-slider .start {
    margin-right: 20px;
}
.time-slider .end {
    margin-left: 20px;
}

.time-slider .start .date,
.time-slider .end .date {
    color: @color-background-dark;
    text-align: right;
    font-size: 12px;
}

.time-slider .start .time,
.time-slider .end .time {
    color: @color-background-light;
    font-size: 13px;
    font-weight: 500;
    text-align: right;
}

.time-slider .end .time,
.time-slider .end .date {
    text-align: left;
}

.time-slider .ant-slider-handle,
.time-slider .ant-slider-handle.ant-tooltip-open,
.time-slider .ant-slider:hover .ant-slider-handle {
    background-color: @color-maxmine-secondary-gradient-start;
    border-color: @color-maxmine-secondary-gradient-start;
}

.time-slider .ant-slider-track,
.time-slider .ant-slider:hover .ant-slider-track {
    background-color: @color-maxmine-highlight-blue;
    height: 2px;
}

.time-slider .ant-slider-rail {
    height: 2px;
    background-color: fade(@color-text-light, 25%) !important;
}

.time-slider .ant-tooltip {
    top: 0 !important;
    pointer-events: none;
}

.time-slider .ant-tooltip .ant-tooltip-content .ant-tooltip-inner {
    background-color: transparent;
    border: 0;
    box-shadow: none;
    margin-top: 10px;
    font-size: 12px;
}
.time-slider .ant-tooltip .ant-tooltip-arrow {
    display: none;
}

.time-slider .ant-slider-rail,
.time-slider .ant-slider:hover .ant-slider-rail {
    background-color: fade(@color-text-light, 25%) !important;
}

.das-playback-footer.ant-layout-footer {
    background-color: @color-maxmine-primary-base;
    box-shadow: inset 2px 0 10px rgba(0, 0, 0, 0.2);
    position: relative;
    width: 100%;
    bottom: 0;
    animation-name: dasFooterShow;
    animation-duration: 0.5s;

    & .das-play-button.ant-btn,
    & .das-play-button .ant-btn,
    & .das-play-button.ant-btn:focus,
    & .das-play-button .ant-btn:focus {
        background-color: @color-maxmine-secondary-gradient-start;
        border: unset;
        width: 64px;
        border-radius: 4px;
    }

    & .das-play-button.das-play-button-zoom.ant-btn,
    & .das-play-button.das-play-button-zoom .ant-btn {
        background-color: @color-maxmine-secondary-gradient-start;
    }

    & .das-play-button.ant-btn:hover {
        background-color: #48a2e6;
    }

    & .das-play-button.das-play-button-zoom.ant-btn:hover {
        background-color: #f7b14c;
    }

    & .das-play-button.ant-btn[disabled],
    & .das-play-button.ant-btn[disabled]:hover,
    & .das-play-button.das-play-button-zoom.ant-btn[disabled],
    & .das-play-button.das-play-button-zoom.ant-btn[disabled]:hover,
    & .das-play-button .ant-btn[disabled],
    & .das-play-button .ant-btn[disabled]:hover,
    & .das-play-button.das-play-button-zoom .ant-btn[disabled],
    & .das-play-button.das-play-button-zoom .ant-btn[disabled]:hover {
        background-color: @color-maxmine-primary-dark;
        border-color: @color-maxmine-primary-darkest;
        color: @color-text-light;
    }
}

.playback-speed-control {
    display: flex;
    flex: 1;
    align-items: center;
}

.playback-speed-control .label {
    color: @color-background-light;
    margin-right: 20px;
    font-size: 10px;
    margin-top: -16px;
}

.playback-speed-control .slider {
    flex: 1;
}

.playback-speed-control .ant-slider-handle {
    background-color: @color-maxmine-secondary-gradient-start;
    border-color: @color-maxmine-secondary-gradient-start;
}

.playback-speed-control .ant-slider-track,
.playback-speed-control .ant-slider:hover .ant-slider-track {
    background-color: fade(@color-text-light, 25%);
    height: 2px;
}

.playback-speed-control .ant-slider-rail,
.playback-speed-control .ant-slider:hover .ant-slider-rail {
    height: 2px;
    color: @color-background-light;
    background-color: fade(@color-text-light, 25%);
}

.playback-speed-control .ant-slider-dot {
    width: 2px;
    height: 2px;
    top: -1px;
}

.playback-speed-control .ant-slider-dot-active {
    border-color: @color-background-light;
}

.playback-speed-control .ant-slider-mark-text {
    color: @color-background-light;
    font-size: 12px;
}

.playback-zoom-control {
    display: flex;
    flex: 1;
    align-items: center;
}

.playback-zoom-control-value {
    color: @color-background-light;
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
}

.playback-zoom-control .label {
    color: @color-background-light;
    font-size: 10px;
}

.playback-zoom-control .slider {
    flex: 1;
}

.playback-zoom-control .ant-slider-handle {
    background-color: @color-maxmine-secondary-gradient-start;
    border-color: @color-maxmine-secondary-gradient-start;
}

.playback-zoom-control .ant-slider-track,
.playback-zoom-control .ant-slider:hover .ant-slider-track {
    background-color: @color-background-light;
    height: 2px;
}

.playback-zoom-control .ant-slider-rail,
.playback-zoom-control .ant-slider:hover .ant-slider-rail {
    height: 2px;
    color: @color-background-light;
    background-color: rgba(255, 255, 255, 0.19);
}

.playback-zoom-control .ant-slider-dot {
    width: 2px;
    height: 2px;
    top: -1px;
}

.playback-zoom-control .ant-slider-dot-active {
    border-color: @color-background-light;
}

.playback-zoom-control .ant-slider-mark-text {
    color: @color-background-light;
    font-size: 12px;
}

.das-zoom-button.ant-btn-circle.ant-btn-sm {
    width: 15px;
    height: 15px;
    font-size: 9px;
}

.machine-color-label {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    margin-right: 5px;
    background-color: @color-background-dark;
}

.das-sider.ant-layout-sider .ant-layout-sider-children {
    border-top: 2px solid @color-text-light;
    padding: 10px 10px 10px 30px;
}

.das-sider.ant-layout-sider-collapsed .ant-layout-sider-children {
    padding: 10px;
}
.das-sider .ant-layout-sider-trigger {
    position: relative;
}

.draggable-card {
    cursor: pointer;
    background-color: @color-background-light;
    border-radius: 8px;
    padding: 4px;
    margin-bottom: 5px;
    height: 100%;
    /*fixes border radius issue on drag*/
    transform: translate(0, 0);
}
.draggable-card.dragging {
    background-color: transparent;
    border: 2px dashed @color-background-dark;
}
.draggable-card.dragging div {
    opacity: 0;
}

.machine-data-collapsed {
    font-size: 12px;
    border-radius: 6px;
    width: 100%;
    display: flex;
    padding: 0 10px;
    text-align: left;
    margin-bottom: 6px;
    border: 1px solid #000;
    color: @color-background-light;
}

.machine-data-collapsed-name {
    flex: 1;
    margin-right: 10px;
}

.machine-data-collapsed-value {
    font-weight: 600;
    flex: 1;
    margin-right: 10px;
    text-align: right;
}

.charts {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    height: 100%;
}

.chart-card-chart {
    width: 720px;
}

.chart-card-chart .highcharts-background {
    fill: #fafafa;
}

.chart-card-collapsed {
    max-height: 150px;
}

.chart-card-collapsed-title,
.chart-card-title {
    cursor: move;
    font-size: 10px;
    color: @color-text-dark;
    margin-bottom: 10px;
}

.chart-chard-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.chart-card-values {
    display: flex;
    width: 520px;
    max-height: 24px;
    justify-content: flex-end;
}

.chart-card-values .machine-data-collapsed {
    margin-left: 6px;
    max-width: 120px;
}

.chart-card-title {
    padding-left: 10px;
    padding-top: 10px;
}

.das-clearable-button button {
    display: flex;
    flex: 1;
    align-items: center;
    border-radius: 6px;
    font-size: 12px;
    margin-bottom: 5px;
}

.das-clearable-button button span {
    flex: 1;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
}
.das-clearable-button button i {
    text-align: right;
}

.das-parameter-buttons {
    display: flex;
    flex: 1;
    margin-bottom: 20px;
}

.das-parameter-buttons .das-clearable-button {
    width: 100px;
    margin-right: 10px;
}

.slider {
    position: relative;
}

.slider-highlight {
    background-color: @color-maxmine-secondary-gradient-start;
    height: 2px;
    width: 100px;
    z-index: 100;
    position: absolute;
    top: 18px;
}

.slider .ant-slider-handle {
    z-index: 1000;
}

.das-parameter-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.das-parameter-list li {
    padding: 6px;
    margin-bottom: 4px;
}

.das-parameter-modal .ant-modal-content {
    border-radius: 6px;
    padding: 20px;
    max-height: 80vh;
}

.das-parameter-categories {
    max-height: calc(80vh - 350px);
}

.das-parameter-list {
    overflow-y: auto;
    max-height: calc(80vh - 350px);
}

.das-parameter-modal-subtitle {
    font-size: 14px;
    margin-bottom: 20px;
    border-bottom: 1px solid #f2f2f2;
    padding-bottom: 10px;
}

.das-parameter-category h3 {
    font-size: 14px;
    text-transform: uppercase;
}

.das-parameter-modal-count {
    color: #bfbfbf;
    float: right;
}

.das-map-data-loading {
    position: absolute;
    top: 50%;
    left: 50%;
}

@keyframes dasFooterShow {
    from {
        position: absolute;
        bottom: -200px;
        opacity: 0;
    }
    to {
        bottom: 0px;
        opacity: 1;
    }
}

.das-playback-footer {
}

.ant-slider.das-slider-range {
    height: 0px;
    margin: 0px;
    padding: 0px;
}

.ant-slider.das-slider-range .ant-slider-rail,
.ant-slider.das-slider-range .ant-slider-step {
    display: none;
    pointer-events: none;
}

.ant-slider.das-slider-range .ant-slider-track,
.ant-slider.das-slider-range:hover .ant-slider-track {
    background-color: transparent;
    border-bottom: 2px dashed @color-maxmine-secondary-gradient-start;
    padding-bottom: 18px;
    z-index: 10001;
    pointer-events: none;
}

.ant-slider.das-slider-range .ant-slider-handle,
.ant-slider.das-slider-range:hover .ant-slider-handle,
.ant-slider.das-slider-range:hover .ant-slider-handle:not(.ant-tooltip-open) {
    width: 0;
    height: 0;
    border-radius: 0px;
    margin-left: -8px;
    border: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 14px solid @color-maxmine-secondary-gradient-start;
    background-color: transparent;
}

.ant-slider.das-slider-range.das-not-zoomed .ant-slider-track {
    display: none;
}

.ant-slider.das-slider-range.das-not-zoomed .ant-slider-handle,
.ant-slider.das-slider-range.das-not-zoomed:hover .ant-slider-handle {
    border-top-color: fade(@color-text-light, 20%);
}
.ant-slider.das-slider-range.das-not-zoomed .ant-slider-handle:hover {
    border-top-color: @color-maxmine-secondary-gradient-start;
}

.ant-slider.das-slider-range.das-zoom-loaded .ant-slider-track {
    border-bottom-style: solid;
}

.ant-slider.das-slider-range.das-zoom-loaded.das-zoom-no-data
    .ant-slider-track {
    border-bottom-color: #ff0000;
}

.das-sider {
    .charts {
        height: calc(100% - 56px);
        overflow-y: auto;
        overflow-x: hidden;
    }
}

.das-sider.ant-layout-sider-collapsed {
    .charts {
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
    }
}

// Style the tooltip when using 'outside'
.das-chart-tooltip span {
    background: @color-background-white;
    padding: @spacing-sm;
    font-family: 'Forno MX';
    font-size: 12px;
}

.chart-parameter-modal-selected-parameter {
    display: flex;
    margin: @spacing-sm 0;
    min-height: 32px;
    &-button {
        margin-right: @spacing-sm;
    }
}

// Avoid the line and fills created by the svg tooltip
.das-chart-tooltip-box {
    stroke: none;
    fill-opacity: 0;
}
.highcharts-tooltip.das-chart-tooltip {
    filter: url(#drop-shadow-0);
}
