.HoldingStatesPane {
    &__TruckHoldingStatePanel {
        margin: 4px;
        margin-bottom: 0;
        padding-top: 4px;
    }

    &__HeaderTitle {
        color: @color-text-light;
        margin-bottom: 0;
    }

    &__Slide {
        display: flex;
    }

    &__SlideElement {
        flex: 1 1 100%;

        &--One {
            flex: 1 1 100%;
        }

        &--Half {
            flex: 1 1 50%;
            max-width: 50%;
        }

        &--Third {
            flex: 1 1 33%;
            max-width: 33%;
        }

        &--Quarter {
            flex: 1 1 25%;
            max-width: 25%;
        }
    }
}
