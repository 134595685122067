.TyresAndSafetyMap__ConformanceHeader {
    background: @color-maxmine-primary-base;
    color: @color-background-light;
}

.TyresAndSafetyMap__MinesiteMapContainer {
    height: 637px;
}

// Display the chart and table in a nicely spaced vertical column
.TyresAndSafety__RightContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}

.TyresAndSafety__MapLegend {
    display: flex;
    justify-content: space-between;
}

.TyresAndSafety__MapLegend__MapFilters {
    // Has a fixed size so it doesn't jump around when toggling
    width: 140px;
}
