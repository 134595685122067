.Heatmap_legend_bar {
    display: flex;
    flex-wrap: nowrap;
}

.Heatmap_legend_bar > div {
    width: 45px;
    height: 18px;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    justify-content: center;
}
