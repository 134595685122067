.LoadUnitImprovementSymbolContainer {
    background: @color-maxmine-primary-dark;
    border-radius: 5px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: @spacing-sm;

    & path {
        stroke: white;
        fill: white;
    }
}
