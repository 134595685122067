@background-high: @color-conformance-3-high;
@background-med: @color-conformance-3-medium;
@background-low: @color-conformance-3-low;

.ResultsDelayStatus {
    display: flex;
    justify-content: center;
    align-items: flex-end;

    & .LeagueTable {
        width: 100%;
        // Styling for conformance cells
        &__Cell__Conformance {
            &--Low {
                background: @background-low;
            }
            &--Medium {
                background: @background-med;
            }
            &--High {
                background: @background-high;
            }
            &.LeagueTable__Cell--Disabled {
                background: none;
            }
        }
        &__Spinner {
            color: @color-text-white;
        }
    }

    // Styling for the legend
    & .Legend {
        padding-left: @spacing-md;

        &__Item--0 {
            fill: @background-low;
        }
        &__Item--1 {
            fill: @background-med;
        }
        &__Item--2 {
            fill: @background-high;
        }
        &__Item--3 {
            fill: none;
            & rect {
                stroke: @color-text-light;
            }
        }
    }
}
