.BiasLoadPositionChart {
    float: left;
}

.BiasLoadPositionChart .background {
    fill: red;
    fill-opacity: 0.3;
    border: 1px solid black;
}

.BiasLoadPositionChart .truckcab {
    fill: grey;
}
