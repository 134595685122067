.GearLockdown {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
}

.GearLockdown--Active {
    background-color: @status-color-red;
    color: white;
}

.GearLockdown--Border {
    border: 1px solid black;
}

.GearLockdown__Header {
    font-weight: 700;
}

.GearLockdown__Row {
    display: flex;
    justify-content: center;
}
