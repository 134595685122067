.LeagueTable {
    // Set our font color
    .ant-table {
        color: @color-text-dark;

        & .ant-tooltip-open {
            background-color: @color-maxmine-primary-dark;
        }
    }

    // Since our footer is a table in of itself with no data,
    // we need to hide the placeholder
    &__Footer {
        .ant-table-placeholder {
            display: none;
        }
    }

    // Styling for conformance cells
    &__Cell__Conformance {
        text-align: center;
        padding: @league-table-cell-conformance-padding;
        border-radius: @rounded-corner-radius;

        // Don't fit the whole cell, give a bit of spacing
        margin: 0 @spacing-xxl;
        // Make sure the text is dark, even if in a header/footer
        color: @color-text-dark;
        transition: all 1s;
    }

    // Grey out cell if disabled
    & &__Cell__Conformance&__Cell {
        &--Disabled,
        &__Conformance--Empty {
            background: none;
            color: @league-table-disabled-color;
            border: 1px solid @color-text-light;
            transition: all 1s;
        }
    }

    // Styling for the dash displayed when there's no data
    &__Dash {
        color: @league-table-disabled-color;
    }

    // Column dividers
    & .LeagueTableDivider {
        &--Left {
            border-left: @table-divider-border;
        }
        &--Top {
            border-top: @table-divider-border;
        }
        &--Right {
            border-right: @table-divider-border;
        }
        &--Bottom {
            border-bottom: @table-divider-border;
        }
    }

    // Header and footer stylings
    & thead th {
        // Font color
        color: @table-header-and-footer-color;
        // Background color
        background: @table-header-and-footer-bg;
        // Font bold
        font-weight: @font-weight-bolder;
        padding: @spacing-sm;
    }

    // Header border on bottom
    & thead {
        border-bottom: @table-divider-border;

        // Override the sortable column header background to dark blue
        // When clicked, override the column header background as dark blue
        & th.ant-table-column-has-actions.ant-table-column-has-sorters:hover,
        & th.ant-table-column-sort {
            background: @color-maxmine-primary-dark;
        }
    }

    // Footer border on top
    & .LeagueTable__Footer thead {
        border-top: @table-divider-border;
        border-bottom: none;
    }

    // Smaller spacings for each row
    & .ant-table-tbody > tr > td > div {
        padding: @spacing-sm;
    }

    & .ant-table-tbody > tr > td {
        padding: 0;
    }

    // Set the row hover color
    & .ant-table-tbody > tr:hover > td {
        background: @color-density-single-8-2;
    }
}

// Ant overrides, we encapsulate it in this class as an opt in
.LeagueTable--Scroll {
    // Get the header to not show a scrollbar
    & .ant-table-fixed-header .ant-table-scroll .ant-table-header {
        // Some javascript in Ant is directly setting margin='-15px' / overflow on the element
        // Using the important is the only way to get it off
        // Take a look at the inspector if you need convincing
        overflow: hidden !important;
        margin-bottom: 0px !important;
    }

    // Same here
    // Stops the scrollbar from messing up the spacing when visible
    & .ant-table-body {
        overflow: overlay !important;
    }
}

.LeagueTable__Row--NonStriped {
    background: rgba(0, 0, 0, 0.03);
}

.LeagueTable__Row--NonStriped--AssetStatus > td > div {
    background: white;
    border-bottom: 1px solid #dce2f0;
}

.LeagueTable__Row--Striped > td > div {
    background: rgba(0, 0, 0, 0.06);
}

.LeagueTable__Row--Striped--AssetStatus > td > div {
    background: #f5fbff;
    border-bottom: 1px solid #dce2f0;
}

.LeagueTable--TextAlignCenter {
    text-align: center;
}

.LeagueTable--Ellipsis {
    td,
    th {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

// This can be useful when using conformance colours which fill an entire cell
.LeagueTable .ant-table-tbody > tr > td.LeagueTable--NoPadding {
    padding: 0;
}

// Remove some default antd styling so we can get our own heading alignments to work
.ant-table-thead > tr > th .ant-table-header-column {
    display: block;
}
.ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters {
    display: flex;
    justify-content: space-between;
}
.ant-table-column-title {
    width: 100%;
}

@temp-font-color-light: rgb(174, 179, 184);
@temp-background-color-dark: rgb(247, 249, 251);

.LeagueTable--V2 {
    thead th {
        background-color: @temp-background-color-dark;
    }

    .LeagueTable__Header__Cell {
        text-transform: uppercase;
        font-size: @font-size-sm;
        color: @temp-font-color-light;
    }

    .ant-table-expanded-row {
        background-color: @temp-background-color-dark;

        & td:first-child {
            border-left: 4px solid @color-maxmine-primary-base;
        }
    }
}
