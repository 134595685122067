html {
    height: -webkit-fill-available;
    height: 100vh;

    overflow: hidden;
}

.Layout {
    // This prevents overflow on graphs when pages are first loaded
    display: flex;
    flex-direction: row;

    & .Header__Logo--Container {
        height: 56px !important;

        & .Layout__Logo--Maxmine {
            width: 151px;
            height: 25px;
        }
    }

    &__Logo {
        // Responsive image
        &--Maxmine & {
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            min-width: 60%;
        }

        &--Client & {
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            width: 100%;
            height: 100%;
        }

        &__Container {
            background-color: unset;

            // Align the logo in the centre
            display: flex;
            justify-content: flex-start;
            padding: @spacing-lg;

            // Set the logo container to stay in fixed flex position
            flex: 0 0 auto;
        }
    }

    &__Sider {
        & > .ant-layout-sider-children {
            // Use a flexbox to display the logo > navigation > logo vertically
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            background-color: @color-maxmine-primary-base;
        }

        & .Navigation {
            padding-right: 1px;
            // hide the scroll bar
            &::-webkit-scrollbar-track {
                background: transparent;
                padding-left: 2px;
                padding-right: 2px;
                border: solid 3px transparent;
            }

            &::-webkit-scrollbar {
                background: transparent;
                width: 8px;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: @spacing-md;
                background-color: @color-text-light;
                border-right: solid 4px transparent;
                background-clip: padding-box;
            }
        }
    }

    &__Header {
        background-color: @color-maxmine-primary-base;
        height: @layout-header-height;
        padding: 0px;
        display: flex;
        justify-content: space-between;
    }

    &__SubHeader {
        background-color: @color-background-light;

        // Make slightly smaller than the regular header
        height: @layout-subheader-height;
        line-height: @layout-subheader-height;

        border-bottom: 1px solid @color-border-dim;

        padding-left: @spacing-md;
        padding-right: 0px;

        &__Right {
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }

    &__Title {
        font-size: large;
        line-height: 0px;
    }

    &__Content {
        background-color: @color-background-white;
        // Ensure the content container fills vertically
        flex: auto;
        // Enable scrolling if the content overflows
        overflow-x: hidden;
        overflow-y: auto;

        padding: @spacing-md @spacing-xl @spacing-md @spacing-lg;
    }

    // Fit the content to the entire drawer
    &__Drawer .ant-drawer {
        &-body {
            padding: 0px;
        }
        &-wrapper-body,
        &-body {
            display: flex;
        }
    }

    // Get the navigation bar to fill the whole container
    & .Navigation {
        flex: auto;
    }
}
