@first-col-width: 50%;
@col-width: 16.6666%;

.LoadsInRangeDetailTable__CellHeader {
    height: 67px;
    width: @col-width;
    cursor: pointer;
}

.LoadsInRangeDetailTable__CellHeader:first-child {
    width: @first-col-width;
}

.LoadsInRangeDetailTable__Row:nth-child(even) {
    background-color: @table-stripe-background;
}

.LoadsInRangeDetailTable .LoadsInRangeDetailTable__Row--Active,
.LoadsInRangeDetailTable .LoadsInRangeDetailTable__Row--Active:hover {
    background-color: steelblue;
    color: white;
}

.LoadsInRangeDetailTable__Cell {
    width: @col-width;
}

.LoadsInRangeDetailTable__Cell:first-child {
    width: @first-col-width;
}

.LoadsInRangeDetailTable__Tbody.LoadsInRangeDetailTable__Tbody {
    max-height: 430px;
}
