@dispatch-border-color-default: white;
@dispatch-border-color-is-over: mediumseagreen;
@dispatch-border-color-can-drop: skyblue;

@dispatch-border-style-default: solid;
@dispatch-border-style-active: dashed;

@dispatch-border-size: 2px;

@dispatch-table-row-height: 141px;

// Override the table borders so different borders can be shown for drop targets
.DispatchTable .ant-table table {
    border-collapse: separate;
    border-spacing: 0;
}

.DispatchTable .ant-table-tbody > tr {
    & > td {
        background: #f4f5f7;
        height: @dispatch-table-row-height;
    }

    &.Circuit--Disabled > td {
        background-color: @color-background-dark;
        opacity: 0.6;
    }
}

.DispatchTable .ant-table-thead > tr > th {
    text-align: center;
    word-break: normal;
}

// General drag styling
.Dispatch__Drag {
    border: @dispatch-border-size @dispatch-border-style-default transparent;
}

.Dispatch__Drag--IsOver {
    border: @dispatch-border-size @dispatch-border-style-active
        @dispatch-border-color-is-over;
}

.Dispatch__Drag--CanDrop {
    border: @dispatch-border-size @dispatch-border-style-active
        @dispatch-border-color-can-drop;
}

// Apply a constant transparent border to avoid reflow
.DispatchTable .ant-table-thead > tr > th,
.DispatchTable .ant-table-tbody > tr > td {
    border: @dispatch-border-size @dispatch-border-style-default
        @dispatch-border-color-default;
}

// When the row is a valid drop target
.DispatchTable .ant-table-tbody > tr.Dispatch__Drag--CanDrop > td {
    border-top: @dispatch-border-size @dispatch-border-style-active
        @dispatch-border-color-can-drop;
    border-bottom: @dispatch-border-size @dispatch-border-style-active
        @dispatch-border-color-can-drop;
}

.DispatchTable .ant-table-tbody > tr.Dispatch__Drag--CanDrop > td:first-child {
    border-left: @dispatch-border-size @dispatch-border-style-active
        @dispatch-border-color-can-drop;
}
.DispatchTable .ant-table-tbody > tr.Dispatch__Drag--CanDrop > td:last-child {
    border-right: @dispatch-border-size @dispatch-border-style-active
        @dispatch-border-color-can-drop;
}

// When the row selected as a drop target
.DispatchTable .ant-table-tbody > tr.Dispatch__Drag--IsOver > td {
    border-top: @dispatch-border-size @dispatch-border-style-active
        @dispatch-border-color-is-over;
    border-bottom: @dispatch-border-size @dispatch-border-style-active
        @dispatch-border-color-is-over;
}

.DispatchTable .ant-table-tbody > tr.Dispatch__Drag--IsOver > td:first-child {
    border-left: @dispatch-border-size @dispatch-border-style-active
        @dispatch-border-color-is-over;
}

.DispatchTable .ant-table-tbody > tr.Dispatch__Drag--IsOver > td:last-child {
    border-right: @dispatch-border-size @dispatch-border-style-active
        @dispatch-border-color-is-over;
}

.HaulArrowIcon {
    margin-left: @spacing-sm;
}

.ReturnArrowIcon {
    margin-right: @spacing-sm;
}

// Table column styles
.DispatchTable__SourceDest {
    text-align: center;
    word-wrap: break-word;
}

.DispatchTable__HaulRow {
    flex: 1 1 @dispatch-table-row-height / 2;
    border-bottom: 1px solid white;
    height: (@dispatch-table-row-height / 2) - 1px;
}

// Overly specific to override ant designs styles
.DispatchTable .ant-table-tbody > tr > td.DispatchTable__HaulRow__tr {
    padding-top: 0;
    padding-bottom: 0;
}

.DispatchTable__ReturnRow {
    height: (@dispatch-table-row-height / 2) - 1px;
    border-top: 1px solid white;
    flex: 1 1 @dispatch-table-row-height / 2;
}

.DispatchTable__CycleDuration {
    text-align: center;
}

.BinnedCell {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
}

.BinnedCell__EquipmentContainer {
    width: 100%;
    position: absolute;
}

.DispatchTable__Column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 100%;
}
