.RoadLabel {
    background-color: fade(@color-background-white, 40%);
    font-weight: 600;
    border: none;
    box-shadow: none;
    outline: none;
}

.RoadLabel::before {
    bottom: 0;
    border: none;
}
