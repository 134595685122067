.MapViewPage__ChartHeader {
    display: flex;
    justify-content: center;
    font-size: 0.825rem;
}

.MapViewPage__Chart__Tooltip {
    width: 170px;
    z-index: 1;
}

.MapViewMap__Annotation {
    font-size: 0.85rem;
}

.MapView__MinesiteMapContainer {
    height: 550px;
}

.TruckAlertsAnalysis__MapViewChart {
    .highcharts-column-series rect.highcharts-point {
        stroke: @color-display-7-1;
    }

    .highcharts-column-series rect.highcharts-point-hover {
        stroke: #5292c4;
    }

    .highcharts-column-series .highcharts-point-hover {
        fill-opacity: 1;
        fill: #5292c4;
        transition: fill-opacity 0ms;
    }

    // Disable opacity on other columns when hovering
    .highcharts-series-inactive {
        opacity: 1;
    }
}
