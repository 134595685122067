.Hexagon {
    fill-opacity: 0.6;
    stroke-width: 2;

    &__HRC--Lowest {
        stroke: @color-severity-5-lowest;
        fill: @color-severity-5-lowest;
    }
    &__HRC--Low {
        stroke: @color-severity-5-low;
        fill: @color-severity-5-low;
    }
    &__HRC--Medium {
        stroke: @color-severity-5-medium;
        fill: @color-severity-5-medium;
    }
    &__HRC--High {
        stroke: @color-severity-5-high;
        fill: @color-severity-5-high;
    }
    &__HRC--Highest {
        stroke: @color-severity-5-highest;
        fill: @color-severity-5-highest;
    }
}
