.Layer__Selector {
    &__Container {
        display: flex;
        flex-direction: column;
        width: fit-content;
        border: 1px solid black;
        border-top: 0;
        background-color: white;
        &__Title {
            padding: 10px;
            text-align: center;
            margin-bottom: 5px;
            font-weight: bold;
            font-size: 90%;
            border-bottom: 1px solid black;
            border-top: 1px solid black;
        }
        &__Scale {
            ul {
                margin: 0;
                margin-bottom: 5px;
                padding: 0;
                display: grid;
            }
        }
    }
}
