// Note these styles match the leaflet default styles for layer controls
.MinesiteMap__Layer__Container {
    background-color: @color-background-white;
    padding: 10px;
    border-radius: 5px;
    color: @color-text-dark;
    margin-bottom: 5px;
}

.MinesiteMap__Layer__Container__RowIcon {
    margin-right: 5px;
    height: 14px;
    width: 14px;
}

.MinesiteMap__Legend__Container {
    display: flex;
    flex-direction: column;
}

.MinesiteMap__Legend__Trigger {
    align-self: flex-end;
}

.MinesiteMap__Legend__Container {
    background: rgba(255, 255, 255, 0.9);
    border-radius: 0.5em;
    padding: @spacing-sm;

    &.clickable,
    & .clickable {
        cursor: pointer;
    }
}

.MinesiteMap__Legend__Popup__Container {
    position: relative;
    padding: 1em;
    background: rgba(255, 255, 255, 0.95);
    border-radius: 0.5em;
    transition: all 5s ease-in-out;
    z-index: 9999;

    & .close {
        position: absolute;
        top: 0.5em;
        right: 0.5em;
        font-size: 1.5em;
        transition: all 200ms;
        font-weight: bold;
        text-decoration: none;
        color: #000;
        z-index: inherit;
        cursor: pointer;

        &:hover {
            color: #1b1b1b;
        }
    }

    & .content {
        max-height: 50%;
    }
}
