// Use the severity colours
.PercentageAreaChart-series,
.PercentageAreaChart-series path {
    &.highcharts-color-0 {
        .color-series-override(@color-severity-5-highest);
    }
    &.highcharts-color-1 {
        .color-series-override(@color-severity-5-high);
    }
    &.highcharts-color-2 {
        .color-series-override(@color-severity-5-medium);
    }
    &.highcharts-color-3 {
        .color-series-override(@color-severity-5-low);
    }
    &.highcharts-color-4 {
        .color-series-override(@color-severity-5-lowest);
    }
}

.PercentageAreaChart-series path {
    // Highlight the hovered points using our theme colour
    &.highcharts-point-hover {
        .color-series-override(@color-maxmine-primary-light);
    }

    // Highlight selected points using our theme colour
    &.highcharts-point-select {
        .color-series-override(@color-maxmine-primary-dark);
    }
}

// Show a vertical crosshair when hovering
.PercentageAreaChart-crosshair.highcharts-crosshair {
    stroke: @color-maxmine-primary-dark;
    stroke-opacity: 1;
}
