.BiasLoadTable td,
.BiasLoadTable th {
    width: 18.75%;
}

.BiasLoadTable td:first-child,
.BiasLoadTable th:first-child {
    width: 25%;
}

.BiasLoadTable tr:nth-child(even) {
    background-color: #f2f2f2;
}

/*TODO move this to global scss files, figure out why this wasn't working with scss imports*/
/*Adding class scroll to a table will make the header fixed*/
/*For each table you will need to define the column widths*/
table.scroll {
    width: 100%;
    border-spacing: 0;
}

table.scroll th,
table.scroll td,
table.scroll tr,
table.scroll thead,
table.scroll tfoot,
table.scroll tbody {
    display: block;
}

table.scroll tfoot tr,
table.scroll thead tr {
    width: calc(100% - 15px);
}

table.scroll tr:after {
    content: ' ';
    display: block;
    visibility: hidden;
    clear: both;
}

table.scroll tbody {
    max-height: 60vh;
    overflow-y: scroll;
}

table.scroll tbody td,
table.scroll tfoot td,
table.scroll thead th {
    float: left;
}
