.MinesiteMap__Legend {
    &__Container {
        display: flex;
        flex-direction: column;
        padding: 0;
    }

    &__Row {
        display: flex;
        align-items: center;
        padding: 2px 0;
        flex: 0 1 auto;
    }

    &__Icon {
        flex: 1 1 0%;
        height: 18px;

        & .Equipment__StatusIcon {
            padding: unset
        }
    }
    &__Text {
        flex: 5 1 0%;
        padding-left: 2px;
    }
}
