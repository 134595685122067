.LoadUnitImprovementSymbol {
    &__IconWrapper {
        display: inline-flex;
        margin: 0 0.5rem 0 0.5rem;
        align-items: center;
        justify-content: center;
    }

    // Use the same type of configuration as antd so things work the same way
    // We set the width & height to be 'em' so that we can control the
    // icon size through font-size.
    // Duplicating the classname declaration to increase the specificity and
    // override font-awesomes width attribute.
    // Once we no longer use font-awesome we could go back to a regular single selector
    &__Icon&__Icon {
        height: 2em;
        width: 2em;
    }
}
