/*
Table of contents (TIP: search for number, eg. "1.")
	1. Colours
		1.1 Primary colours
			- Brand primary colour, forms the base of many of the other styles
			- Use the base variant as the first choice
		1.2 Secondary colours - Use the base variant as the first choice
			- Brand primary colour, forms the base of many of the other styles
			- Use the base variant as the first choice
		1.3 Surface and Background Colours
			- Use these to style the background area of a page
		1.4 Typography and Iconagraphy Colours
			- Use these when selecting font colours
		1.5 Severity Colours
			- Base colours for indicating when something has a good to bad scale.
			- These should generally not be used directly, the most common use case will be
			 for generating a new set colours, eg. conformance scores
		1.6 Status Colours
			- Use these for general positive negative behaviours throughout the app
			Eg. an error has occurred use, @status-color-red
		1.7 Density Colours - Single
		1.8 Density Colours - Double
		1.9 Display Colours
			- These are all variations for use with values that scale
			A common use case is for rash maps and charts
		1.10 Chart styles
			- The default colours applied to charts
		1.11 Network Heatmap colours
	2. MaxMine behaviour
		- Contains variables for use relating to MaxMine definitions of behaviour
		Eg. an "Off" truck should always be represented by the same styles
	3. General spacing / styling / layout
		- Variables for adjusting the layout or base styling
		- Use @spacing-md as a first choice
	4. Font Styles
	5. Table
		- Variables for the default styling of a LeagueTable
    6. Component specific styles
        6.1 TAT Holding states
        6.2 TAT Equipment card
	7. Ant design overrides
		- Variables from ant design we're overriding
*/

/*
	1. Colors
*/
// 1.1 Primary Colours
@color-maxmine-primary-base: #1e232d;
@color-maxmine-primary-lightest: #373c46;
@color-maxmine-primary-light: lighten(@color-maxmine-primary-base, 10%);
@color-maxmine-primary-dark: darken(@color-maxmine-primary-base, 10%);
@color-maxmine-primary-darkest: #040913;

@color-maxmine-primary-cta: #193f74;
@color-maxmine-highlight-blue: #00afff;

@alert-warning-bg-color: #fde68a;

// 1.2 Secondary Colours
@color-maxmine-secondary-gradient-start: #ff8700;
@color-maxmine-secondary-gradient-end: #ffa500;
@color-maxmine-secondary-base: linear-gradient(
    90deg,
    @color-maxmine-secondary-gradient-start,
    @color-maxmine-secondary-gradient-end
);
@color-maxmine-secondary-lightest: lighten(
    @color-maxmine-secondary-gradient-start,
    25%
);
@color-maxmine-secondary-light: lighten(
    @color-maxmine-secondary-gradient-start,
    10%
);
@color-maxmine-secondary-dark: darken(
    @color-maxmine-secondary-gradient-start,
    10%
);
@color-maxmine-secondary-darkest: darken(
    @color-maxmine-secondary-gradient-start,
    20%
);

// 1.3 Surface and Background Colours
@color-background-white: #ffffff;
@color-background-light: #f1f5f9;
@color-background-dark: #cccccc;

// 1.4 Typography and Iconagraphy Colours
@color-text-dark: #262626;
@color-text-darkest: #3b4252;
@color-text-light: #e6e6e6;
@color-text-white: #fffafa;

@color-border-dim: fade(@color-text-light, 25%);

// 1.5 Severity Colours
@color-severity-5-lowest: #1eb980;
@color-severity-5-highest: #de4d46;
@color-severity-5-medium: #f5bf38;

@league-table-color-severity-5-lowest: #00d25a;
@league-table-color-severity-5-low: #7fca2d;
@league-table-color-severity-5-medium: #ffc300;
@league-table-color-severity-5-high: #ff752a;
@league-table-color-severity-5-highest: #ff2855;

// Generate the intermediate severity colours using
// mix of the 2 adjacent colours
@color-severity-5-low: mix(
    @color-severity-5-lowest,
    @color-severity-5-medium,
    50%
);
@color-severity-5-high: mix(
    @color-severity-5-highest,
    @color-severity-5-medium,
    75%
);

// 1.6 Status Colours
@status-color-green: #1eb980;
@status-color-amber: #f5bf38;
@status-color-red: #cd2c24;

// 1.7 Density Colours - Single
// Generate the density single shade from the primary colour
@color-density-single-8-1: fadeout(@color-maxmine-primary-base, 100%);
@color-density-single-8-2: fadeout(@color-maxmine-primary-base, 90%);
@color-density-single-8-3: fadeout(@color-maxmine-primary-base, 75%);
@color-density-single-8-4: fadeout(@color-maxmine-primary-base, 60%);
@color-density-single-8-5: fadeout(@color-maxmine-primary-base, 45%);
@color-density-single-8-6: fadeout(@color-maxmine-primary-base, 30%);
@color-density-single-8-7: fadeout(@color-maxmine-primary-base, 15%);
@color-density-single-8-8: fadeout(@color-maxmine-primary-base, 0%);

// 1.8 Density Colours - Double
// Generate the density double shade using the primary and secondary colours
@color-density-double-8-1: mix(
    @color-maxmine-secondary-lightest,
    @color-maxmine-primary-base,
    100%
);
@color-density-double-8-2: mix(
    @color-maxmine-secondary-lightest,
    @color-maxmine-primary-base,
    90%
);
@color-density-double-8-3: mix(
    @color-maxmine-secondary-lightest,
    @color-maxmine-primary-base,
    75%
);
@color-density-double-8-4: mix(
    @color-maxmine-secondary-lightest,
    @color-maxmine-primary-base,
    60%
);
@color-density-double-8-5: mix(
    @color-maxmine-secondary-lightest,
    @color-maxmine-primary-base,
    45%
);
@color-density-double-8-6: mix(
    @color-maxmine-secondary-lightest,
    @color-maxmine-primary-base,
    30%
);
@color-density-double-8-7: mix(
    @color-maxmine-secondary-lightest,
    @color-maxmine-primary-base,
    15%
);
@color-density-double-8-8: mix(
    @color-maxmine-secondary-lightest,
    @color-maxmine-primary-base,
    0%
);

// 1.9 Display Colours
@color-display-7-1: #1f77b4;
@color-display-7-2: #ff7f0e;
@color-display-7-7: #2ca02c;
@color-display-7-3: #d62728;
@color-display-7-4: #9467bd;
@color-display-7-5: #8c564b;
@color-display-7-6: #e377c2;

// 1.10 Chart styles
@fill-opacity-chart-area: 0.35;

@color-chart-best-practice: @color-severity-5-lowest;
@color-chart-top-five: darken(@color-severity-5-medium, 15%);
@color-chart-bottom-five: @color-severity-5-highest;

// 1.11 Network Heatmap Colours
// colors to mix
@_red: #e53e3e;
@_green: #48bb78;
// generates css class red-green-1000-{0,100,200......1000}
.color-palette(@color1Name, @color1, @color2Name, @color2, @max, @step, @value:0)
    when
    (@value =< (@max)) {
    .@{color2Name}-@{color1Name}-@{max}-@{value} {
        background-color: mix(@color1, @color2, (@value / @max) * 100);
    }
    // Need these for styling SVGs that use stroke and fill
    .@{color2Name}-@{color1Name}-@{max}-@{value}-stroke {
        stroke: mix(@color1, @color2, (@value / @max) * 100);
    }
    .@{color2Name}-@{color1Name}-@{max}-@{value}-fill {
        fill: mix(@color1, @color2, (@value / @max) * 100);
    }
    .color-palette(@color1Name, @color1, @color2Name, @color2, @max, @step, @value + @step);
}
// mix two colors
.color-palette(green,@_green,red,@_red,1000,100);

/*
	2. MaxMine specific behaviour
 */
@color-maxmine-equipment-off: @color-background-dark;
@color-maxmine-not-expected: #e6e6e6;
// For displays with no results, eg. empty table cell
@color-conformance-non-accessed: #c0c0c0;

@color-conformance-3-low: tint(@color-severity-5-highest, 50%);
@color-conformance-3-medium: tint(@color-severity-5-medium, 50%);
@color-conformance-3-high: tint(@color-severity-5-lowest, 50%);

@league-table-color-conformance-3-low: @league-table-color-severity-5-highest;
@league-table-color-conformance-3-medium: @league-table-color-severity-5-medium;
@league-table-color-conformance-3-high: @league-table-color-severity-5-lowest;

@color-conformance-5-lowest: tint(@color-severity-5-highest, 50%);
@color-conformance-5-low: tint(@color-severity-5-high, 50%);
@color-conformance-5-medium: tint(@color-severity-5-medium, 50%);
@color-conformance-5-high: tint(@color-severity-5-low, 50%);
@color-conformance-5-highest: tint(@color-severity-5-lowest, 50%);

@league-table-color-conformance-5-lowest: @league-table-color-severity-5-highest;
@league-table-color-conformance-5-low: @league-table-color-severity-5-high;
@league-table-color-conformance-5-medium: @league-table-color-severity-5-medium;
@league-table-color-conformance-5-high: @league-table-color-severity-5-low;
@league-table-color-conformance-5-highest: @league-table-color-severity-5-lowest;

/*
	3. General spacing / styling / layout
 */
@spacing-sm: 8px;
@spacing-md: 16px;
@spacing-lg: 24px;
@spacing-xl: 32px;
@spacing-xxl: 40px;

@rounded-corner-radius: 5px;

@layout-header-height: 56px;
@layout-subheader-height: 60px;
@layout-client-logo-path: '';

/*
	4. Font Styles
*/
@font-weight-base: 400;
@font-weight-bold: 500;
@font-weight-bolder: 700;

@font-family: 'Forno MX', sans-serif;

/*
	5. Table styles
	TODO refactor these
 */
@table-header-and-footer-bg: @color-maxmine-primary-base;
@table-header-and-footer-color: @color-text-white;

@table-divider-size: 3px;
@table-divider-color: @color-maxmine-primary-lightest;
@table-divider-border: @table-divider-size solid @table-divider-color;

// TODO can be removed after LoadUnitDetailTable is refactored to a LeagueTable
@table-stripe-background: #f2f2f2;

@league-table-cell-conformance-padding: 4px;
@league-table-disabled-color: @color-text-light;

/*
    6. Component specific styles
 */
// 6.1 TAT holding states
@truck-holding-state-background-grey: lightgrey;
@truck-holding-state-border-radius: 6px;
@truck-holding-state-divider-width: 3px;

// 6.2 TAT equipment card
@equipment-card-width: 65px;
@equipment-card-height: 50px;
@Equipment-card-border: 1px solid @color-text-light;
// Transitions for hover and highlight colours
@equipment-card-transition: all 0.2s;

@equipment-header-footer-font-size: 10px;
@equipment-card-badge-font-size: 12px;

/*
	7. Ant design overrides
 */
@color-old-maxmine-primary-blue: #2f5c9b;
@primary-color: @color-old-maxmine-primary-blue;
@border-radius-base: 0;
