.LoadsInRangeDetailChartManager__ButtonContainer {
    display: flex;
    align-items: center;
    margin-left: 57px;
}

.LoadsInRangeDetailChartManager__ButtonSection {
    margin-right: 30px;
}

.LoadsInRangeDetailChartManager__PlotMetaOptionsContainer {
    flex-grow: 2;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.LoadsInRangeDetailChartManager__PlotMetaOptionsContainerBinSize {
    width: 65px;
}

.LoadsInRangeDetailChartManager {
    & .FalconChart__chart {
        // There exists some (foundation?) table css that messes with the tooltip styling
        // This is to override that behaviour
        & .highcharts-tooltip {
            &,
            & table,
            & tr,
            & td {
                background: unset;
                margin-bottom: unset;
                padding: unset;
                font-size: 12px;
                color: rgba(0, 0, 0, 0.65);
            }
        }
    }
}
