@first-alert-col-width: 1.5cm;
@col-width: 0.3cm;
@header-col-width: 0.5cm;
@conformance-cell-height: 12px;
@page-padding-top: 1cm;
@header-col-height: 1.5cm;

.FleetStatusPrintTable__Wrapper {
    page-break-after: always;
    page-break-inside: avoid;
    padding-top: @page-padding-top;
    padding-right: 1.5cm;
}

.FleetStatusPrintTable__Wrapper:first-child {
    padding-top: 0;
}

.FleetStatusPrintTable__Wrapper:last-child {
    page-break-after: auto;
}

.FleetStatusPrintTable {
    table-layout: fixed;
    width: 100%;
    text-align: center;
    border-collapse: collapse;
    font-size: 0.6rem;
    border: none;
}

.FleetStatusPrintTable th,
.FleetStatusPrintTable td {
    font-size: 0.825em;
    text-align: center;
    border: 1px solid grey;
    padding: 0;
}

.FleetStatusPrintTable td {
    width: @col-width;
    line-height: @conformance-cell-height;
}

.FleetStatusPrintTable tr td:first-child {
    white-space: nowrap !important;
    overflow: hidden !important;
    width: @first-alert-col-width !important;
    text-align: left;
}

.FleetStatusPrintTable th div {
    writing-mode: vertical-rl;
    height: @header-col-height;
    text-align: center;
    display: inline-block;
}

.FleetStatusPrintTable tr th:first-child {
    width: @first-alert-col-width;
}

.FleetStatusPagePrint .ConformanceLegend__Icon {
    height: 10px;
    width: 10px;
    margin: 0;
    margin-right: 0.5rem;
}

.FleetStatusPagePrint .ConformanceLegend__Label {
    font-size: 0.825em;
}

.FleetStatusPrintTable__ConformanceCell {
    text-align: center;
    height: @conformance-cell-height;
    padding: 0;
}

.FleetStatusPrintTable__ConformanceCellWrapper {
    height: @conformance-cell-height;
    width: 100%;
    position: relative;
}

.FleetStatusPrintTable__ConformanceCellBackgroundImg {
    display: block;
    height: @conformance-cell-height;
    width: 100%;
}

.FleetStatusPrintTable__ConformanceCellValue {
    height: @conformance-cell-height;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.FleetStatusPrintTable__PageBreakTr {
    page-break-after: always;
    display: block;
}
