.TableLink {
    font-size: 18px;
    color: @color-maxmine-primary-base;
    display: inline-block;
    border: 1px solid @color-maxmine-primary-base;
    padding: 3px 7px 3px 7px;
    margin-right: 5px;
    cursor: pointer;
}

.TableLink:hover {
    color: @color-background-light;
    background-color: @color-maxmine-primary-base;
}

.TableLinkTooltip {
    width: 200px;
}

@media print {
    .TableLink {
        display: none;
    }
}
