.loading-spinner {
    &-overlay {
        height: 100vh;
        width: 100vw;
        position: fixed;
        z-index: 999;
        right: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.5);
        overflow-x: hidden;
        transition: 0.5s;
    }

    & {
        padding: 20px;
        position: fixed;
        z-index: 2000000000;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 20px;
    }

    &-icon {
        font-size: 30px;
    }
}
