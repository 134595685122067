// This will force the table body to overflow and scroll, since there is not enough room
.LoaderLeagueTable {
    height: 65vh;
}

// Table Links
.LoaderLeagueTable .TableLink {
    padding: 0px 7px 0px 7px;
    margin: 1px;
}

.LoaderLeagueTable .TableLinkSpacer {
    margin-right: 0.3em;
}

.LoaderLeagueTable .rt-tbody .rt-td {
    padding: 0;
}

// Footer
.ReactTable .rt-tfoot .rt-td {
    &:nth-child(n + 2) {
        text-align: center;
    }
}

.LoaderLeague__ConformanceCell {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.LoaderLeague__ConformanceCellValuesWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.LoaderLeague__ConformanceCellSymbolWrapper {
    position: absolute;
    transform: translate(3rem, 0);
}

.ReactTable--IE .LoaderLeague__ConformanceCellSymbolWrapper {
    transform: translate(3rem, -50%);
}

@media print {
    body {
        margin: 5mm;
    }
    .LoaderLeagueTable {
        height: inherit !important;
    }
    .LoaderLeagueTable .rt-thead .rt-tr {
        background: @color-background-light !important;
    }
}
