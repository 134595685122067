.map__checkbox-overspeed-in-corners {
    color: red !important;
}

.map__checkbox-ramp-events {
    color: darkorange !important;
}

.map__checkbox-speeding-in-zone {
    color: grey !important;
}
