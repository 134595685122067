.Legend {
    display: flex;

    &--Direction--Row {
        flex-direction: row;
    }

    &--Direction--RowReverse {
        flex-direction: row-reverse;
    }

    &--Direction--Column {
        flex-direction: column;
    }

    &--Direction--ColumnReverse {
        flex-direction: column-reverse;
    }

    &--Direction--Row {
        .Legend__Title {
            margin-right: @spacing-md;
        }
    }

    &--Direction--RowReverse {
        .Legend__Title {
            margin-left: @spacing-md;
        }
    }

    &__Title {
        display: flex;
        font-weight: @font-weight-bold;
        font-size: @font-size-lg;
        margin-bottom: @spacing-md;

        &--FlexStart {
            justify-content: flex-start;
        }

        &--FlexEnd {
            justify-content: flex-end;
        }

        &--Center {
            justify-content: center;
        }

        &--ItemDirection--Row {
            margin-right: @spacing-md;
            margin-bottom: @spacing-sm;
        }

        &--ItemDirection--RowReverse {
            margin-left: @spacing-sm;
            margin-bottom: @spacing-md;
        }

        &--ItemDirection--Column {
            margin-right: @spacing-sm;
            align-items: center;
        }

        &--ItemDirection--ColumnReverse {
            margin-left: @spacing-sm;
            align-items: center;
        }

        &--ItemDirection--ColumnReverse,
        &--ItemDirection--Column {
            margin-bottom: @spacing-sm;
        }

        // NOTE: this is specifically after &__ItemDirection--Column/Reverse so it can override it
        &--Direction--RowReverse,
        &--Direction--Row {
            margin-bottom: 0;
        }

        &--Direction--ColumnReverse {
            order: 1;
        }
    }

    .Legend--Direction--Column &__Row,
    .Legend--Direction--ColumnReverse &__Row {
        margin-bottom: @spacing-sm;
    }

    &__Row {
        display: flex;
        align-items: center;

        &--ItemDirection--Row {
            flex-direction: row;
            align-self: flex-start;
        }

        &--ItemDirection--RowReverse {
            flex-direction: row-reverse;
            align-self: flex-end;
        }

        &--ItemDirection--Column {
            flex-direction: column;
            margin-right: @spacing-md;
        }

        &--ItemDirection--ColumnReverse {
            flex-direction: column-reverse;
            margin-right: @spacing-md;
        }
    }

    &__Label {
        &--ItemDirection--Row {
            margin-left: @spacing-sm;
            margin-right: @spacing-md;
        }

        &--ItemDirection--RowReverse {
            margin-left: @spacing-md;
            margin-right: @spacing-sm;
        }

        &--ItemDirection--Column {
            margin-top: @spacing-sm;
        }

        &--ItemDirection--ColumnReverse {
            margin-bottom: @spacing-sm;
        }
    }
}
