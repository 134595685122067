.NavbarItemContainer {
    margin-right: @spacing-sm;
    display: flex;

    & button,
    & button:hover {
        background: transparent;
        background-color: transparent;
        border: unset;
    }
}
