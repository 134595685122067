.PivotGridCell--Ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.PivotGridCell--Rotated {
    transform: rotate(-90deg);
    &:extend(.PivotGridCell--Ellipsis);
}

.PivotGridCell--Background {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    margin-left: 1px;
}
