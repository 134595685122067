.FleetHistory__PivotGrid {
    .PivotGrid__LeftHeaderGridContainer__HeaderGrid__HeaderCell,
    .PivotGrid__RightHeaderGridContainer__HeaderGrid__HeaderCell {
        cursor: pointer;
    }

    &__SortArrow {
        bottom: 16px;
        font-weight: @font-weight-bolder;
        position: absolute;
    }
}
