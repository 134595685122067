@surfaceRoughness: {
    lowest: @surface-roughness-color-lowest;
    low: @surface-roughness-color-low;
    medium: @surface-roughness-color-medium;
    high: @surface-roughness-color-high;
    highest: @surface-roughness-color-highest;
};
each(@surfaceRoughness, {
  .MinesiteMap__Legend__RowIcon--SurfaceRoughness-@{key} {
    background-color: @value;
  }
});
