.Header__Logo {
    &--Container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: @spacing-md @spacing-lg @spacing-md;

        &.flex-start {
            justify-content: flex-start;
        }

        & .Layout__Logo--Maxmine {
            display: flex;
            justify-content: flex-start;
        }
    }
}
