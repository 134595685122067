.SynchronisedChart__container {
    // Put some space between each graph, using Ant Design spacing values
    & > div {
        padding-top: @spacing-sm;
    }

    // This stops the animation when hovering over a legend item.
    // When there are synchronised charts, hovering over a legend item only shows the animation for 1 chart
    // so we just disable it completely here
    &
        .highcharts-legend-series-active
        g.highcharts-series:not(.highcharts-series-hover),
    &
        .highcharts-legend-point-active
        .highcharts-point:not(.highcharts-point-hover),
    &
        .highcharts-legend-series-active
        .highcharts-markers:not(.highcharts-series-hover),
    &
        .highcharts-legend-series-active
        .highcharts-data-labels:not(.highcharts-series-hover) {
        opacity: 1;
    }
}
