.DoubleSeriesChart {
    .highcharts-color-0 {
        .color-series-override(@color-maxmine-secondary-dark);
    }
    .highcharts-color-1 {
        .color-series-override(@color-maxmine-primary-dark);
    }
    .highcharts-color-2 {
        .color-series-override(@color-maxmine-secondary-light);
    }
    .highcharts-color-3 {
        .color-series-override(@color-maxmine-primary-light);
    }
    .FalconChart__tooltip-series {
        &--0 {
            color: @color-maxmine-secondary-dark;
        }
        &--1 {
            color: @color-maxmine-primary-dark;
        }
        &--2 {
            color: @color-maxmine-secondary-light;
        }
        &--3 {
            color: @color-maxmine-primary-light;
        }
    }
}

.SingleSeriesChart {
    .highcharts-color-0 {
        .color-series-override(@color-maxmine-secondary-dark);
    }
    .highcharts-color-1 {
        .color-series-override(@color-maxmine-secondary-light);
    }
    .FalconChart__tooltip-series {
        &--0 {
            color: @color-maxmine-secondary-dark;
        }
        &--2 {
            color: @color-maxmine-secondary-light;
        }
    }
}

.ExhaustGasTempChart {
    .highcharts-color-0 {
        .color-series-override(@color-maxmine-secondary-dark);
    }
    .highcharts-color-1 {
        .color-series-override(@color-maxmine-primary-dark);
    }
    .FalconChart__tooltip-series {
        &--0 {
            color: @color-maxmine-secondary-dark;
        }
        &--2 {
            color: @color-maxmine-primary-dark;
        }
    }
}
