.TickCheckbox {
    user-select: none;
}

.TickCheckbox {
    font-size: inherit;
    color: inherit;
}

.TickCheckbox__Checkbox {
    display: none;
}

// When checked - bold the text only
.TickCheckbox__Checkbox:checked ~ span {
    font-weight: bold;
}

// Add a border (stroke) to the checkbox icon (gear / x / circle)
.TickCheckbox__Checkbox ~ span i {
    text-shadow: -1px -1px 0 #333, 1px -1px 0 #333, -1px 1px 0 #333,
        1px 1px 0 #333;
}

// Checked boxes have a fill color - when unchecked change the color to white
.TickCheckbox__Checkbox:not(:checked) ~ span i {
    color: #fff;
}
