.DateSelector {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    &__field {
        margin-right: 10px;
        & label {
            display: inline-block;
            width: 36px;
            text-align: end;
            padding-right: 4px;
        }
    }
    &__validation {
        margin: auto 0;
        color: red;
    }
}
