.HaulTruckFillFactorHistogramLegend {
    position: absolute;
    left: 70px;
    top: -50px;
    background: white;
    padding: 5px;
    border: 1px solid black;
    margin-top: 50px;
}

.HaulTruckFillFactorHistogramLegend .LegendRow {
    display: flex;
    alignitems: center;
}

.HaulTruckFillFactorHistogramLegend .Icon {
    width: 30px;
    height: 20px;
    display: inline-block;
}

.Icon.TargetPayload {
    border-bottom: 3px dashed black;
    transform: translate(0, -50%);
}

.Icon.MeanPayload {
    border-bottom: 3px dashed red;
    transform: translate(0, -50%);
}

.Icon.GoodTarget {
    background-color: green;
    opacity: 0.5;
}

.Icon.AvgTarget {
    background-color: yellow;
    opacity: 0.5;
}
