.TruckHoldingState {
    background: @color-maxmine-primary-lightest;
    border-radius: @truck-holding-state-border-radius;
    padding: @spacing-md;
}

.TruckPlaceholder {
    background: @color-background-white;
}

.TruckHoldingState__ContainerRight {
    padding-bottom: @spacing-md;
}

.TruckHoldingState__ContainerRight--grey {
    background: @truck-holding-state-background-grey;
}

.TruckHoldingStatePanel {
    background: @color-maxmine-primary-base;
    padding: @spacing-sm;
}

.TruckHoldingStatePanel__HeaderRight {
    display: flex;
    justify-content: flex-end;
}

.TruckHoldingStatePanel__HeaderLeft,
.TruckHoldingStatePanel__HeaderLeft .ant-typography {
    color: @color-text-white;
}

.TruckHoldingState__PanelTitle {
    color: @color-text-white;
    font-size: @font-size-lg;
}
