// Remove the default leaflet div icon styles
.leaflet-div-icon {
    border: none;
    background: none;
}

@marker-border-size: 5px;
@marker-size: 40px;
@marker-cluster-background: @color-background-white;
@marker-foreground: @color-background-white;

.MinesiteMap__MarkerClusterContainer {
    background-color: @marker-cluster-background;
    border-radius: 50%;
    width: @marker-size;
    height: @marker-size;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Marker__Content {
    left: 0;
    top: 0;
    position: absolute;
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Marker__Foreground {
    fill: @marker-foreground;
}

.Marker__Bold {
    font-weight: bolder;
    font-size: large;
    color: #000;
    border: 3px solid black;
    border-radius: 16px;
}

@truckAlertLevels: {
    low: @color-severity-5-lowest;
    medium: @color-severity-5-medium;
    high: @color-severity-5-highest;
};
each(@truckAlertLevels, {
    .Marker__Background--@{key} {
        fill: @value;
    }

    .MinesiteMap__MarkerClusterContainer--@{key} {
        border: @marker-border-size solid @value;
        box-shadow: 0 0 0 @marker-border-size fadeout(@value, 50%);
    }
})
    .animated-leaflet-marker {
    transition: all 5s;
}
