@color-is-bad: @color-conformance-3-low;
@color-is-good: @color-conformance-3-high;
@adjust-color-percent: 20%;

.HaulTruckFillFactor__TruckFilterOption {
    &IsGoodPayload {
        &,
        &.Select-option {
            background: @color-is-good;
            &.is-selected,
            &.is-focused,
            &:hover {
                background: lighten(@color-is-good, @adjust-color-percent);
            }
        }
    }

    &IsBadPayload {
        &,
        &.Select-option {
            background: @color-is-bad;
            &.is-selected,
            &.is-focused,
            &:hover {
                background: lighten(@color-is-bad, @adjust-color-percent);
            }
        }
    }
}

.HaulTruckFillFactor__TruckFilterLegendContainer {
    display: flex;
    justify-content: center;
    height: 73px;
    flex-direction: column;
}

.HaulTruckFillFactor__TruckFilterLegendRow {
    display: flex;
    margin-bottom: 0.5rem;
}

.HaulTruckFillFactor__TruckFilterLegendRowIcon() {
    width: 20px;
    height: 20px;
}

.HaulTruckFillFactor__TruckFilterLegendRowIcon--Good {
    .HaulTruckFillFactor__TruckFilterLegendRowIcon();
    background: @color-is-good;
}

.HaulTruckFillFactor__TruckFilterLegendRowIcon--Bad {
    .HaulTruckFillFactor__TruckFilterLegendRowIcon();
    background: @color-is-bad;
}

.HaulTruckFillFactor__TruckFilterLegendRowLabel {
    font-size: 0.8rem;
    margin-left: 1rem;
}
