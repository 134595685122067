@surface-roughness-color-lowest: @color-severity-5-lowest;
@surface-roughness-color-low: @color-severity-5-low;
@surface-roughness-color-medium: @color-severity-5-medium;
@surface-roughness-color-high: @color-severity-5-high;
@surface-roughness-color-highest: @color-severity-5-highest;

.SurfaceRoughness--Lowest {
    stroke: @surface-roughness-color-lowest;
}

.SurfaceRoughness--Low {
    stroke: @surface-roughness-color-low;
}

.SurfaceRoughness--Medium {
    stroke: @surface-roughness-color-medium;
}

.SurfaceRoughness--High {
    stroke: @surface-roughness-color-high;
}

.SurfaceRoughness--Highest {
    stroke: @surface-roughness-color-highest;
}
