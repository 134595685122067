// Use this file to add site specific themes
// It'd be better to split this out in the future
// and build seperate bundles for each minesite rather
// than include all the assets like we're doing now

.acme-adelaide {
    // ACME Adelaide don't show a logo so hide it
    & .Layout__Logo--Client {
        display: none;
    }
}

// Client logo
.Layout__Logo--Client {
    margin: unset !important;
    padding: @spacing-lg!important;

    border-top: 1px solid rgba(#e6e6e6, 25%);
}

//acme-adelaide
.acme-adelaide {
}

//batchfire-callide
.batchfire-callide {
    & .Layout__Logo--Client {
        background: transparent;

        & .Layout__Logo {
            width: 180px;
            height: 19px;
            background-image: url('../images/site/batchfire-callide.png');
        }
    }
}

//coronado-curragh
//coronado-curraghmain
.coronado-curragh,
.coronado-curraghmain {
    & .Layout__Logo--Client {
        background: transparent;
        & .Layout__Logo {
            width: 137px;
            height: 49px;
            background-image: url('../images/site/coronado-curragh.png');
        }
    }
}

//glencore-goedgevonden
.glencore-goedgevonden {
    & .Layout__Logo--Client {
        background: transparent;

        & .Layout__Logo {
            width: 142px;
            height: 23px;
            background-image: url('../images/site/glencore.png');
        }
    }
}

//kinross-tasiast
.kinross-tasiast {
    & .Layout__Logo--Client {
        background: transparent;

        & .Layout__Logo {
            width: 119px;
            height: 36px;
            background-image: url('../images/site/kinross.png');
        }
    }
}

//macmahon-byerwen
.macmahon-byerwen {
    & .Layout__Logo--Client {
        background: transparent;

        & .Layout__Logo {
            width: 160px;
            height: 37px;
            background-image: url('../images/site/macmahon.png');
        }
    }
}

//macmahon-foxleigh
.macmahon-foxleigh {
    & .Layout__Logo--Client {
        background: transparent;

        & .Layout__Logo {
            width: 160px;
            height: 37px;
            background-image: url('../images/site/macmahon.png');
        }
    }
}

//macmahon-telfer
.macmahon-telfer {
    & .Layout__Logo--Client {
        background: transparent;

        & .Layout__Logo {
            width: 160px;
            height: 37px;
            background-image: url('../images/site/macmahon.png');
        }
    }
}

//macmahon-tropicana
.macmahon-tropicana {
    & .Layout__Logo--Client {
        background: transparent;

        & .Layout__Logo {
            width: 154px;
            height: 54px;
            background-image: url('../images/site/macmahon-tropicana.png');
        }
    }
}

//mrl-wodgina
.mrl-wodgina {
    & .Layout__Logo--Client {
        background: transparent;

        & .Layout__Logo {
            width: 184px;
            height: 36px;
            background-image: url('../images/site/mineral-resources.png');
        }
    }
}

//nrw-karara
.nrw-karara {
    & .Layout__Logo--Client {
        background: transparent;

        & .Layout__Logo {
            width: 129px;
            height: 41px;
            background-image: url('../images/site/nrw.png');
        }
    }
}

//regis-duketon
.regis-duketon {
    & .Layout__Logo--Client {
        background: transparent;

        & .Layout__Logo {
            width: 155px;
            height: 36px;
            background-image: url('../images/site/regis.png');
        }
    }
}
