@grid-labels-width: 100px;
@grid-labels-border: 1px solid lightgrey;
@label-width: 50px;
@grid-labels-background: white;

.TruckHistoryPage__PivotGrid {
    padding-left: @grid-labels-width;
}

.GridLabels__Outer {
    overflow: hidden;
    transform: translate(-@grid-labels-width, 0);
    position: absolute;
    width: @grid-labels-width;
    border-top: @grid-labels-border;
    border-left: @grid-labels-border;
    border-bottom: @grid-labels-border;
}

.MaxMineAlertIdLabels__LabelContainer {
    border-bottom: @grid-labels-border;
    border-right: @grid-labels-border;
    background: @grid-labels-background;
    width: @label-width;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.MaxMineAlertIdLabels__Label {
    transform: rotate(-90deg);
    white-space: nowrap;
}

.AlertLevelLabels {
    transform: translate(@label-width, 0);
    position: absolute;
}

.AlertLevel__Label {
    border-bottom: @grid-labels-border;
    background: @grid-labels-background;
    width: @label-width;
    position: absolute;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.AlertLevel__Label--BorderTop {
    border-top: @grid-labels-border;
}
