@import './zIndex';
@import './commonStyles';
@import './componentStyles';
@import './utils';
@import './printing';
@import (css) '~react-hint/css/index.css';
@import (css) '~react-select/dist/react-select.css';
@import (css) '~react-redux-toastr/lib/css/react-redux-toastr.min.css';
@import (css) '~font-awesome/css/font-awesome.css';
@import './siteSpecificThemes.less';
@import '~@rs/core/falcon/style/index.less';

@font-face {
    font-family: 'Forno MX';
    font-weight: @font-weight-bolder;
    src: url('./fonts/FornoMX-Bold.woff2') format('woff2'),
        url('./fonts/FornoMX-Bold.ttf') format('ttf');
}

@font-face {
    font-family: 'Forno MX';
    font-weight: @font-weight-bold;
    src: url('./fonts/FornoMX-Medium.woff2') format('woff2'),
        url('./fonts/FornoMX-Medium.ttf') format('ttf');
}

@font-face {
    font-family: 'Forno MX';
    src: url('./fonts/FornoMX-Regular.woff2') format('woff2'),
        url('./fonts/FornoMX-Regular.ttf') format('ttf');
}
