.FleetStatusPage__ConformanceLegendIcon--High {
    fill: #d9534f;
}

.FleetStatusPage__ConformanceLegendIcon--Med {
    fill: #f57f17;
}

.FleetStatusPage__ConformanceLegendIcon--Low {
    fill: #ffd600;
}

.FleetStatusTable__PivotGrid {
    // Allow space for the alert labels
    padding-left: 50px;

    & .GridLabels__Outer {
        transform: translate(-50px, 0);
        position: absolute;
        width: 50px;
    }

    & .AlertLevelLabels {
        transform: none;
        position: absolute;
    }
}
