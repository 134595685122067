// Overrides the white arrow and sets the highlight colour
.HighlightableArrow--highlight > i.anticon {
    &.Carousel__NextArrow,
    &.Carousel__PrevArrow {
        color: @color-maxmine-secondary-dark;
    }
}

// Hide default arrows drawn by react-slick
.Carousel__Container {
    & button.slick-arrow {
        visibility: hidden;
    }
}
