.MinesiteMap__MarkerClusterTooltip {
    & .leaflet-popup-content-wrapper {
        border-radius: 0px;
        font-size: 1rem;
        display: flex;
        flex-direction: column;
        font-size: 1rem;
        background: @color-background-white;
        padding: @spacing-sm;
        white-space: normal;
        border: 1px solid @color-background-dark;

        & .leaflet-popup-content {
            margin: 0.25rem;
            z-index: 999;
        }
    }
    // slim version
    &.slim .leaflet-popup-content-wrapper {
        & .leaflet-popup-content {
            margin: 0rem;
        }
    }

    & .leaflet-popup-close-button {
        display: none;
    }
}

.TyresAndSafetyMap__MinesiteMapContainer {
    & .leaflet-popup-pane {
        z-index: 9999;
    }
}
