.loading-spinner {
    padding: 20px;
    position: fixed;
    z-index: 2000000000;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
}

.loading-spinner-icon {
    font-size: 30px;
}
