.Select--multi .Select-value {
    color: #333333;
    background-color: #dddddd;
    border: none;
}

.Select--multi .Select-value-icon {
    border-right: 1px solid #bdbdbd;
}

.Select--multi .Select-value-icon:hover {
    color: black;
    background-color: #bdbdbd;
}

.Select--multi .Select-value,
.Select--single .Select-value {
    border-radius: 0;
    white-space: nowrap;
}

/*.Select-option.is-selected, .Select-option.is-focused {*/
/*background-color: lightgrey;*/
/*}*/

.Select > .Select-menu-outer {
    z-index: @z-index-filters;
}
