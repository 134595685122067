.groupListItem {
    background-color: #f8f8f8;
    margin: 20px 5px;
    padding: 20px;
}
.deleteGroupButton {
    padding: 0 25px;
    color: red;
}
.centerContent {
    text-align: center;
}
.editGroupButton {
    padding: 0 35px;
    color: #005282;
}
.groupInfoSecondary {
    text-align: center;
}

@media only screen and (max-width: 600px) {
    .groupInfoPrimary {
        text-align: center;
        margin-top: 10px;
    }
    .groupInfoSecondary {
        text-align: center;
        margin-bottom: 10px;
    }
}
