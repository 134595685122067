// This is dumping ground of things that don't have a home yet
// but are still required

html,
body {
    margin: 0;
    height: 100%;
    min-height: 100%;
}

.MaxMine {
    .row.full-width {
        width: 100%;
        max-width: 100%;
    }

    .Printing--Show {
        display: none;
    }

    .Validate--error {
        color: @status-color-red;
    }

    @media print {
        // This prevents an extra blank page being added
        html,
        body {
            height: auto;
            margin: 5mm 2.5mm 5mm 2.5mm;
        }
        .Printing--Show {
            display: initial;
        }
        .Printing--Hide {
            display: none;
        }
        table.Printing--Show {
            display: table;
        }
        td.Printing--Show {
            display: table-cell;
        }
    }

    .IFrameVideoWrapper {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        padding-top: 25px;
        height: 0;
    }

    .IFrameVideoWrapper iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    /*
    Browsers: <= IE11
    Issue: IE11 content overflows the SVG when zooming.
    https://connect.microsoft.com/IE/feedbackdetail/view/920757/ies-overflow-behavior-for-svg-elements-doesnt-match-other-browsers
    */
    svg {
        display: block;
        margin: auto;
        overflow: hidden;
    }

    .Table--Default {
        border-collapse: collapse;

        td,
        th {
            padding: 6px 10px;
        }

        th {
            padding-top: 8px;
            cursor: pointer;
            background-color: @color-maxmine-primary-base;
            color: @color-background-light;
        }

        tbody tr:hover {
            background-color: #e9f0f6;
        }

        tfoot {
            background-color: aliceblue;
        }
    }

    .Table--Scroll {
        width: 100%;
        border-spacing: 0;

        th,
        td,
        tr,
        thead,
        tfoot,
        tbody {
            display: block;
        }

        tfoot tr,
        thead tr {
            width: calc(100% - 15px);
        }

        tr:after {
            content: ' ';
            display: block;
            visibility: hidden;
            clear: both;
        }

        tbody {
            max-height: 60vh;
            overflow-y: scroll;
        }

        tbody td,
        tfoot td,
        thead th {
            float: left;
        }

        ::-webkit-scrollbar-track:vertical {
            border-left: 1px solid #e7e7e7;
            box-shadow: 1px 0 1px 0 #f6f6f6 inset, -1px 0 1px 0 #f6f6f6 inset;
        }

        ::-webkit-scrollbar-track:horizontal {
            border-top: 1px solid #e7e7e7;
            box-shadow: 0 1px 1px 0 #f6f6f6 inset, 0 -1px 1px 0 #f6f6f6 inset;
        }

        ::-webkit-scrollbar {
            -webkit-appearance: none;
            background-color: #fafafa;
            width: 16px;
        }

        ::-webkit-scrollbar-thumb {
            background-clip: padding-box;
            background-color: #c1c1c1;
            border-color: transparent;
            border-radius: 9px 8px 8px 9px;
            border-style: solid;
            border-width: 3px 3px 3px 4px; /* Workaround because margins aren't supported */
            box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
        }

        /* Unfortunately scrollbars can't use CSS transitions. Also, it's not possible
       to highlight the thumb when the scrollbar track is hovered without some
       JavaScript acrobatics; https://jsfiddle.net/QcqBM/6/ is a start, but you
       also have to check whether the element has a scrollbar and if so how wide
       it is. */
        ::-webkit-scrollbar-thumb:hover {
            background-color: rgba(0, 0, 0, 0.5);
        }
    }

    .Table__Cell--Number {
        text-align: center;
    }

    .Table__Cell--ConformanceLow {
        background-color: fade(
            @league-table-color-conformance-3-low,
            20%
        ) !important;
        fill: fade(@league-table-color-conformance-3-low, 20%) !important;
    }

    .Table__Cell--ConformanceMedium {
        background-color: fade(
            @league-table-color-conformance-3-medium,
            20%
        ) !important;
        fill: fade(@league-table-color-conformance-3-medium, 20%) !important;
    }

    .Table__Cell--ConformanceHigh {
        background-color: fade(
            @league-table-color-conformance-3-high,
            20%
        ) !important;
        fill: fade(@league-table-color-conformance-3-high, 20%) !important;
    }

    .Table__Cell--ConformanceNonAccessed {
        background-color: fade(@color-conformance-non-accessed, 20%) !important;
        fill: fade(@color-conformance-non-accessed, 20%) !important;
    }

    .Table__Cell--EquipmentOffline {
        background-color: fade(@color-maxmine-equipment-off, 20%) !important;
        fill: fade(@color-maxmine-equipment-off, 20%) !important;
    }

    .Table__Cell--NotExpected {
        background-color: @color-maxmine-not-expected !important;
        fill: @color-maxmine-not-expected !important;
    }

    .ReactTable.ReactTable--Default {
        border: none;
    }

    .ReactTable--Default.ReactTable--Scrollable .rt-tbody {
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .ReactTable--Default.ReactTable--Default .rt-tr-group {
        border-bottom: none;
    }

    // Fix for headers/footers not taking scrollbar into consideration
    .ReactTable--Default.ReactTable--Scrollable:not(.ReactTable--Safari)
        .rt-tfoot
        .rt-tr:last-child,
    .ReactTable--Default.ReactTable--Scrollable:not(.ReactTable--Safari)
        .rt-thead
        .rt-tr:last-child {
        margin-right: 15px;
    }

    .ReactTable--Default .rt-tfoot .rt-td,
    .ReactTable--Default .tr-thead .rt-th,
    .ReactTable--Default .rt-tbody .rt-td {
        border-right: none;
    }

    .ReactTable--Default .rt-tr-group:hover {
        background-color: #e9f0f6;
    }

    .ReactTable--Default .rt-thead.-headerGroups,
    .ReactTable--Default .rt-thead {
        background-color: @color-maxmine-primary-base;
        color: @color-background-light;
        font-weight: 700;
    }

    .ReactTable--Default .rt-tr.-even.ReactTable__Row--Active,
    .ReactTable--Default .rt-tr.-even.ReactTable__Row--Active:hover,
    .ReactTable--Default .rt-tr.-odd.ReactTable__Row--Active:hover,
    .ReactTable--Default .rt-tr.-odd.ReactTable__Row--Active {
        background: steelblue;
        color: white;
    }

    .ReactTable--Default .rt-thead .rt-th.-sort-desc,
    .ReactTable--Default .rt-thead .rt-th.-sort-asc {
        box-shadow: none;
    }

    // Sort Decending color + arrow
    .ReactTable--Default .rt-thead .rt-th.-sort-desc::after {
        font: normal normal normal 14px/1 FontAwesome;
        vertical-align: top;
        content: ' \f077';
    }
    // Sort Ascending color + arrow
    .ReactTable--Default .rt-thead .rt-th.-sort-asc::after {
        font: normal normal normal 14px/1 FontAwesome;
        vertical-align: middle;
        content: ' \f078';
    }

    .ReactTable--Default .rt-tfoot {
        background: aliceblue;
        font-weight: 700;
    }

    .ReactTable--Default .ReactTable__ConformanceCellContainer {
        padding: 0;
    }

    .Layout__Page {
        height: 100%;
        max-height: 100%;
        overflow: hidden;
        & .Page__Background {
            background: @color-maxmine-primary-base;
            height: 100vh - 56px;
        }

        & Layout__Content {
            overflow-y: scroll;
        }
    }

    .Page__SubLayout:not(.isMobile) {
        background: #000c17;
        & .Page__Content {
            // only round the left top corner if in desktop view
            border-top-left-radius: @spacing-sm;
            overflow: hidden;
        }
    }

    .ant-alert-error {
        background-color: #fecdd3;
        border: unset;
    }

    .ant-alert-warning {
        background-color: #fde68a;
        border: unset;
    }
}
