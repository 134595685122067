.mixin-equipment-card-highlight() {
    background-color: lighten(@color-maxmine-secondary-light, 10%);
    border-color: @color-maxmine-secondary-darkest;
}
.mixin-equipment-card-active() {
    background-color: lighten(@color-maxmine-primary-lightest, 25%);
    border-color: @color-maxmine-primary-lightest;
}
.mixin-equipment-card-icon() {
    width: 100%;
    height: 100%;
    flex: 1 1 auto;
    text-align: center;
}

.Equipment {
    &__Card {
        width: @equipment-card-width;
        height: @equipment-card-height;
        border: @Equipment-card-border;
        border-radius: @rounded-corner-radius;
        padding: 0 2px;
        transition: @equipment-card-transition;
        background-color: @color-background-white;

        display: flex;
        flex-direction: column;

        &--highlight {
            .mixin-equipment-card-highlight();
        }

        &--hover {
            .mixin-equipment-card-active();
        }

        &__Container {
            width: @equipment-card-width;
        }

        &__Header {
            flex: 0 0 auto;
            text-align: center;
            font-size: @equipment-header-footer-font-size;
            // Spacing values to get everything to fit
            min-height: 5px;
        }
        &__Footer {
            flex: 0 0 auto;
            text-align: center;
            font-size: @equipment-header-footer-font-size;
            // Spacing values to get everything to fit
            min-height: 8px;
        }

        // Display all content horizontally and evenly spaced (not on content size)
        &__Content {
            flex: 1 1 auto;
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;
            // Explicit height needed for IE11 - doesn't affect anything else
            height: 0px;
        }

        &__Cluster {
            width: @equipment-card-width;

            &.ant-popover-open .Equipment__Card {
                // If we open a cluster, keep the active hover colour on the card
                .mixin-equipment-card-active();
            }

            // Indicate that the card is clickable
            & .Equipment__Card {
                cursor: pointer;
            }

            // Display the cluster content in a 2 column expanding grid
            &__Container {
                display: flex;
                flex-wrap: wrap;
                // Wide enough to make it a 2x2 grid
                width: calc(@equipment-card-width * 2 + @spacing-sm);
                margin: auto;
                & > * {
                    // Padding for children in the cluster
                    margin-left: 3px;
                    margin-top: 3px;
                }
            }

            // Red notification badge CSS
            // Taken from https://codepen.io/bja1011/pen/JYyNZN
            &[data-badge] {
                position: relative;
                &:after {
                    position: absolute;
                    right: -10px;
                    top: -10px;
                    min-width: 10px;
                    min-height: 10px;
                    line-height: 7px;
                    padding: 5px;
                    border: 1px solid @color-text-dark;
                    background-color: @color-background-white;
                    font-weight: @font-weight-bold;
                    font-size: @equipment-card-badge-font-size;
                    border-radius: 20px;
                    content: attr(data-badge);
                }
            }
        }
    }

    &__Icon {
        .mixin-equipment-card-icon();
        padding: 0 2px;
    }

    &__StatusIcon {
        .mixin-equipment-card-icon();
        padding: 0 5px;
        & path,
        & tspan {
            fill: @color-severity-5-highest;
        }
    }
}
