.ErrorBoundary {
    height: 100%;
    width: 100%;
    overflow: auto;
}

.ErrorBoundary__Style {
    min-height: 400px;
    background-color: #ebebeb;
    border-radius: 4px;
    padding: 100px;
}
.ErrorBoundary__Body {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.ErrorBoundary__Icon {
    font-size: 66px;
    color: rgba(0, 0, 0, 0.65);
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.ErrorBoundary__PrimaryMessage {
    text-align: center;
    font-size: 30px;
}
.ErrorBoundary__SecondaryMessage {
    text-align: center;
    font-size: 20px;
}
