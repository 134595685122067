@import (css) '~react-virtualized/styles.css';

@z-index: 1;

@color-header: @color-background-light;

@background-colour-header: @color-maxmine-primary-base;
@background-colour-footer: aliceblue;
@background-colour-fixed-column: whitesmoke;

@border-header: 1px solid @color-border-dim;
@border-footer: 1px solid white;
@border-fixed-column: 1px solid lightgrey;
@border-cells: 1px solid whitesmoke;

@font-weight-header-footer: 700;

.PivotGrid {
    position: relative;
    display: flex;
    flex-direction: row;
}

.PivotGrid__LeftHeaderGridContainer {
    position: absolute;
    left: 0;
    top: 0;
    z-index: @z-index;
}

.PivotGrid__LeftSideGridContainer,
.PivotGrid__LeftFooterGridContainer {
    position: absolute;
    left: 0;
    flex: 0 0 75px;
    z-index: @z-index;
}

.PivotGrid__RightHeaderGridContainer {
    position: absolute;
    right: 0;
    top: 0;
    z-index: @z-index;
}

.PivotGrid__RightFooterGridContainer,
.PivotGrid__RightSideGridContainer {
    position: absolute;
    right: 0;
    flex: 0 0 75px;
    z-index: @z-index;
}

.PivotGrid__GridColumn {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
}

.PivotGrid__LeftSideGridContainer__LeftSideGrid,
.PivotGrid__RightSideGridContainer__RightSideGrid,
.RightSideGrid,
.LeftSideGrid {
    overflow: hidden !important;
}

.PivotGrid__LeftHeaderGridContainer__HeaderGrid,
.PivotGrid__LeftFooterGridContainer__HeaderGrid,
.PivotGrid__RightHeaderGridContainer__HeaderGrid,
.PivotGrid__RightFooterGridContainer__HeaderGrid,
.PivotGrid__GridColumn__HeaderGrid,
.PivotGrid__GridColumn__FooterGrid {
    width: 100%;
    overflow: hidden !important;
}

.PivotGrid__GridColumn__BodyGrid {
    width: 100%;
    overflow-x: scroll !important;
}

.PivotGrid__LeftHeaderGridContainer,
.PivotGrid__RightHeaderGridContainer,
.PivotGrid__GridColumn__HeaderGrid {
    background: @background-colour-header;
    color: @color-header;
}

.PivotGrid__GridColumn__HeaderGrid__HeaderCell,
.PivotGrid__LeftHeaderGridContainer__HeaderGrid__HeaderCell,
.PivotGrid__RightHeaderGridContainer__HeaderGrid__HeaderCell {
    border-left: @border-header;
}

.PivotGrid__GridColumn__HeaderGrid__HeaderCell:last-child {
    border-right: @border-header;
}

.PivotGrid__LeftFooterGridContainer,
.PivotGrid__LeftHeaderGridContainer {
    border-right: @border-header;
}

.PivotGrid__RightFooterGridContainer {
    border-left: @border-header;
}

.PivotGrid__GridColumn__BodyGrid__Cell,
.PivotGrid__LeftSideGridContainer__LeftSideGrid__Cell,
.PivotGrid__RightSideGridContainer__RightSideGrid__Cell,
.PivotGrid__GridColumn__HeaderGrid__HeaderCell,
.PivotGrid__LeftFooterGridContainer__HeaderGrid__HeaderCell,
.PivotGrid__RightFooterGridContainer__HeaderGrid__HeaderCell,
.PivotGrid__LeftHeaderGridContainer__HeaderGrid__HeaderCell,
.PivotGrid__RightHeaderGridContainer__HeaderGrid__HeaderCell,
.PivotGrid__GridColumn__FooterGrid__FooterCell {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    //padding: 0 .5em;
}

.PivotGrid__GridColumn__BodyGrid__Cell {
    background: @color-background-white;
    padding: 1px;
}

.PivotGrid__RightSideGridContainer {
    background: whitesmoke;
    border-left: 1px solid lightgrey;
}

.PivotGrid__LeftSideGridContainer__LeftSideGrid__Cell {
    text-align: left;
}

.PivotGrid__GridColumn__FooterGrid__FooterCell,
.PivotGrid__GridColumn__HeaderGrid__HeaderCell,
.PivotGrid__LeftFooterGridContainer__HeaderGrid__HeaderCell,
.PivotGrid__RightFooterGridContainer__HeaderGrid__HeaderCell,
.PivotGrid__LeftHeaderGridContainer__HeaderGrid__HeaderCell,
.PivotGrid__RightHeaderGridContainer__HeaderGrid__HeaderCell {
    font-weight: @font-weight-header-footer;
}

.PivotGrid__LeftSideGridContainer__LeftSideGrid {
    border-left: @border-fixed-column;
    border-right: @border-fixed-column;
}

.PivotGrid__LeftSideGridContainer {
    background-color: @background-colour-fixed-column;
}

.PivotGrid__GridColumn__FooterGrid,
.PivotGrid__RightFooterGridContainer__HeaderGrid,
.PivotGrid__LeftFooterGridContainer {
    background: @background-colour-footer;
}

.PivotGrid__GridColumn__FooterGrid__FooterCell {
    border: @border-footer;
}

.PivotGrid__RightSideGridContainer {
    background: @background-colour-fixed-column;
    border-left: @border-fixed-column;
    border-right: @border-fixed-column;
}

.PivotGrid__LeftSideGridContainer__LeftSideGrid__Cell,
.PivotGrid__RightSideGridContainer__RightSideGrid__Cell {
    border-bottom: @border-fixed-column;
}
