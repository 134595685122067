// Need to think about more about a naming scheme here
.transition-in-mixin {
    transition: all 0.15s @ease-in;
}

.dashed-line-mixin {
    stroke-dasharray: 2, 4;
}

.PlotBands {
    // Band colours
    &__band {
        &--green {
            fill: @color-severity-5-lowest;
            fill-opacity: 0.2;
        }
        &--yellow {
            fill: @color-severity-5-medium;
            fill-opacity: 0.3;
        }
        &--red {
            fill: @color-severity-5-highest;
            fill-opacity: 0.3;
        }
    }

    // Line styling
    &__line {
        stroke-width: 2px;

        // Dashed
        &--dashed {
            .dashed-line-mixin();
        }

        // Thicken
        &--thick {
            stroke-width: 3px;
        }

        // Colours
        &--green {
            stroke: @color-severity-5-lowest;
        }
        &--yellow {
            stroke: @color-severity-5-medium;
        }
        &--red {
            stroke: @color-severity-5-highest;
        }
        &--black {
            stroke: black;
        }
        &--display-0 {
            stroke: lighten(@color-display-7-1, 15%);
        }
        &--display-1 {
            stroke: lighten(@color-display-7-2, 15%);
        }
        &--display-2 {
            stroke: lighten(@color-display-7-3, 15%);
        }
        &--display-3 {
            stroke: lighten(@color-display-7-4, 15%);
        }
        &--display-4 {
            stroke: lighten(@color-display-7-5, 15%);
        }
        &--display-5 {
            stroke: lighten(@color-display-7-6, 15%);
        }
        &--display-6 {
            stroke: lighten(@color-display-7-7, 15%);
        }
    }
}
