.FileUploaderTable {
    // Set our font color
    .ant-table {
        color: @color-text-dark;

        & .ant-tooltip-open {
            background-color: @color-maxmine-primary-dark;
        }
    }

    // Since our footer is a table in of itself with no data,
    // we need to hide the placeholder
    &__Footer {
        .ant-table-placeholder {
            display: none;
        }
    }

    // Styling for the dash displayed when there's no data
    &__Dash {
        color: @league-table-disabled-color;
    }

    // Column dividers
    & .FileUploaderTableDivider {
        &--Left {
            border-left: @table-divider-border;
        }
        &--Top {
            border-top: @table-divider-border;
        }
        &--Right {
            border-right: @table-divider-border;
        }
        &--Bottom {
            border-bottom: @table-divider-border;
        }
    }

    // Header and footer stylings
    & thead th {
        // Font color
        color: @table-header-and-footer-color;
        // Background color
        background: @table-header-and-footer-bg;
        // Font bold
        font-weight: @font-weight-bolder;
        padding: @spacing-sm;
    }

    // Header border on bottom
    & thead {
        border-bottom: @table-divider-border;

        // Override the sortable column header background to dark blue
        // When clicked, override the column header background as dark blue
        & th.ant-table-column-has-actions.ant-table-column-has-sorters:hover,
        & th.ant-table-column-sort {
            background: @color-maxmine-primary-dark;
        }
    }

    // Footer border on top
    & FileUploaderTable__Footer thead {
        border-top: @table-divider-border;
        border-bottom: none;
    }

    // Smaller spacings for each row
    & .ant-table-tbody > tr > td {
        padding: @spacing-sm;
    }

    // Set the row hover color
    & .ant-table-tbody > tr:hover > td {
        background: @color-density-single-8-2;
    }
}

.FileUploaderTable--Scroll {
    // Get the header to not show a scrollbar
    & .ant-table-fixed-header .ant-table-scroll .ant-table-header {
        // Some javascript in Ant is directly setting margin='-15px' / overflow on the element
        // Using the important is the only way to get it off
        // Take a look at the inspector if you need convincing
        overflow: hidden !important;
        margin-bottom: 0px !important;
    }

    // Same here
    // Stops the scrollbar from messing up the spacing when visible
    & .ant-table-body {
        overflow: overlay !important;
    }
}

.FileUploaderTable__Row--NonStriped {
    background: rgba(0, 0, 0, 0.03);
}

.FileUploaderTable__Row--Striped {
    background: rgba(0, 0, 0, 0.04);
}

.FileUploaderTable--TextAlignCenter {
    text-align: center;
}

.FileUploaderTable--Ellipsis {
    td,
    th {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

// This can be useful when using conformance colours which fill an entire cell
.FileUploaderTable .ant-table-tbody > tr > td.FileUploaderTable--NoPadding {
    padding: 0;
}

.ant-table-thead > tr > th .ant-table-header-column {
    display: block;
}
.ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters {
    display: flex;
    justify-content: space-between;
}
.ant-table-column-title {
    width: 100%;
}

@temp-font-color-light: rgb(174, 179, 184);
@temp-background-color-dark: rgb(247, 249, 251);

.FileUploaderTable--V2 {
    thead th {
        background-color: @temp-background-color-dark;
    }

    .FileUploaderTable__Header__Cell {
        text-transform: uppercase;
        font-size: @font-size-sm;
        color: @temp-font-color-light;
    }

    .ant-table-expanded-row {
        background-color: @temp-background-color-dark;

        & td:first-child {
            border-left: 4px solid @color-maxmine-primary-base;
        }
    }
}

.loading-spinner {
    //background-color: white;
    //border: 2px solid @color-maxmine-primary-base;
    padding: 20px;
    position: fixed;
    z-index: 2000000000;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
}

.loading-spinner-icon {
    font-size: 30px;
}
