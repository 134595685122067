.AxisGridLine:first-child {
    stroke: black;
}

.AxisGridLine {
    stroke: grey;
    opacity: 0.3;
}

.AxisTickLine {
    stroke: black;
    strokewidth: 0.5;
}
