@das-machine-unselected: #cccccc;

.das-machine {
    &--display-0 {
        background: @color-display-7-1;
        border-color: @color-display-7-1;
        &--text {
            color: @color-display-7-1;
        }
        &--disabled {
            color: @color-display-7-1;
            background: transparent;
        }
        &--path {
            stroke: @color-display-7-1;
        }
        &--marker {
            .MarkerPin__Outer {
                fill: @color-display-7-1;
            }
        }
    }
    &--display-1 {
        background: @color-display-7-2;
        border-color: @color-display-7-2;
        &--text {
            color: @color-display-7-2;
        }
        &--disabled {
            color: @color-display-7-2;
            background: transparent;
        }
        &--path {
            stroke: @color-display-7-2;
        }
        &--marker {
            .MarkerPin__Outer {
                fill: @color-display-7-2;
            }
        }
    }
    &--display-2 {
        background: @color-display-7-3;
        border-color: @color-display-7-3;
        &--text {
            color: @color-display-7-3;
        }
        &--disabled {
            color: @color-display-7-3;
            background: transparent;
        }
        &--path {
            stroke: @color-display-7-3;
        }
        &--marker {
            .MarkerPin__Outer {
                fill: @color-display-7-3;
            }
        }
    }
    &--display-3 {
        background: @color-display-7-4;
        border-color: @color-display-7-4;
        &--text {
            color: @color-display-7-4;
        }
        &--disabled {
            color: @color-display-7-4;
            background: transparent;
        }
        &--path {
            stroke: @color-display-7-4;
        }
        &--marker {
            .MarkerPin__Outer {
                fill: @color-display-7-4;
            }
        }
    }
    &--display-4 {
        background: @color-display-7-5;
        border-color: @color-display-7-5;
        &--text {
            color: @color-display-7-5;
        }
        &--disabled {
            color: @color-display-7-5;
            background: transparent;
        }
        &--path {
            stroke: @color-display-7-5;
        }
        &--marker {
            .MarkerPin__Outer {
                fill: @color-display-7-5;
            }
        }
    }
    &--display-5 {
        background: @color-display-7-6;
        border-color: @color-display-7-6;
        &--text {
            color: @color-display-7-6;
        }
        &--disabled {
            color: @color-display-7-6;
            background: transparent;
        }
        &--path {
            stroke: @color-display-7-6;
        }
        &--marker {
            .MarkerPin__Outer {
                fill: @color-display-7-6;
            }
        }
    }
    &--display-6 {
        background: @color-display-7-7;
        border-color: @color-display-7-7;
        &--text {
            color: @color-display-7-7;
        }
        &--disabled {
            color: @color-display-7-7;
            background: transparent;
        }
        &--path {
            stroke: @color-display-7-7;
        }
        &--marker {
            .MarkerPin__Outer {
                fill: @color-display-7-7;
            }
        }
    }
    &--display-grey {
        background: @das-machine-unselected;
        border-color: @das-machine-unselected;
        &--disabled {
            color: @das-machine-unselected;
            background: transparent;
        }
        &--path {
            stroke: @das-machine-unselected;
        }
        &--marker {
            opacity: 0.5;
            .MarkerPin__Outer {
                fill: @das-machine-unselected;
            }
        }
    }
}
