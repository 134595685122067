.ResultsDelayHistory {
    // Styling for the legend
    & .Legend {
        &__Item--0 {
            fill: @color-conformance-3-low;
        }
        &__Item--1 {
            fill: @color-conformance-3-medium;
        }
        &__Item--2 {
            fill: @color-conformance-3-high;
        }
    }
}
