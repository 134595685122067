@thead-row-height: 50px;
@operator-column-width: 0.25;
@available-non-conformance-column-width: 0.375;
@available-conformance-column-width: 0.375;

.LeagueTable {
    th {
        height: @thead-row-height;
    }

    td {
        height: 51px;
    }

    tfoot td {
        background: aliceblue;
    }
}

.LeagueTable .tbody {
    max-height: 48vh;
}

.conformanceColumnWidthCalc(@available-width, @col-count, @classname) {
    @col-width: @available-width / @col-count;

    td.@{classname},
    th.@{classname} {
        width: percentage(@col-width);
    }

    thead tr:first-child .@{classname} {
        width: percentage(@available-width);
    }

    thead tr:first-child th:last-child {
        text-align: center;
        width: percentage(@available-width);
    }
}

.nonConformanceColumnWidthCalc(@available-width, @col-count, @classname) {
    @col-count-minus-operator-col: @col-count - 1;
    @col-width: @available-width / @col-count-minus-operator-col;

    td.@{classname},
    th.@{classname} {
        width: percentage(@col-width);
    }

    td:first-child.@{classname},
    th:first-child.@{classname} {
        width: percentage(@operator-column-width);
    }

    thead tr:first-child th:first-child {
        width: percentage(@available-width + @operator-column-width);
    }

    tr:nth-child(2) th:nth-child(@{col-count}) {
        border-right: 1px solid @color-border-dim;
    }
}

.LeagueTable--NonConformanceColumnCountIs4 {
    .nonConformanceColumnWidthCalc(
        @available-non-conformance-column-width,
        4,
        LeagueTable--NonConformanceCell
    );
}

.LeagueTable--NonConformanceColumnCountIs5 {
    .nonConformanceColumnWidthCalc(
        @available-non-conformance-column-width,
        5,
        LeagueTable--NonConformanceCell
    );
}

.LeagueTable--NonConformanceColumnCountIs6 {
    .nonConformanceColumnWidthCalc(
        @available-non-conformance-column-width,
        6,
        LeagueTable--NonConformanceCell
    );
}

.LeagueTable--ConformanceColumnCountIs3 {
    .conformanceColumnWidthCalc(
        @available-conformance-column-width,
        3,
        LeagueTable--ConformanceCell
    );
}

.LeagueTable--ConformanceColumnCountIs4 {
    .conformanceColumnWidthCalc(
        @available-conformance-column-width,
        4,
        LeagueTable--ConformanceCell
    );
}

.LeagueTable--ConformanceColumnCountIs5 {
    .conformanceColumnWidthCalc(
        @available-conformance-column-width,
        5,
        LeagueTable--ConformanceCell
    );
}

.LeagueTable--ConformanceColumnCountIs6 {
    .conformanceColumnWidthCalc(
        @available-conformance-column-width,
        6,
        LeagueTable--ConformanceCell
    );
}

.LeagueTable--ConformanceColumnCountIs7 {
    .conformanceColumnWidthCalc(
        @available-conformance-column-width,
        7,
        LeagueTable--ConformanceCell
    );
}

.LeagueTable--NonConformanceColumnCountIs2.LeagueTable--ConformanceColumnCountIs7 {
    @non-conformance-column-count: 2;
    @non-conformance-column-width: @available-non-conformance-column-width /
        @non-conformance-column-count;
    @additional-width: @available-non-conformance-column-width -
        @non-conformance-column-width + (@non-conformance-column-width / 3);
    .nonConformanceColumnWidthCalc(
        @available-non-conformance-column-width - @additional-width,
        @non-conformance-column-count,
        LeagueTable--NonConformanceCell
    );
    .conformanceColumnWidthCalc(
        @available-conformance-column-width + @additional-width,
        7,
        LeagueTable--ConformanceCell
    );
}

.LeagueTable--NonConformanceColumnCountIs2.LeagueTable--ConformanceColumnCountIs6 {
    @non-conformance-column-count: 2;
    @non-conformance-column-width: @available-non-conformance-column-width /
        @non-conformance-column-count;
    @additional-width: @available-non-conformance-column-width -
        @non-conformance-column-width + (@non-conformance-column-width / 3);
    .nonConformanceColumnWidthCalc(
        @available-non-conformance-column-width - @additional-width,
        @non-conformance-column-count,
        LeagueTable--NonConformanceCell
    );
    .conformanceColumnWidthCalc(
        @available-conformance-column-width + @additional-width,
        6,
        LeagueTable--ConformanceCell
    );
}

.LeagueTable--NonConformanceColumnCountIs2.LeagueTable--ConformanceColumnCountIs5 {
    @non-conformance-column-count: 2;
    @non-conformance-column-width: @available-non-conformance-column-width /
        @non-conformance-column-count;
    @additional-width: @available-non-conformance-column-width -
        @non-conformance-column-width + (@non-conformance-column-width / 3);
    .nonConformanceColumnWidthCalc(
        @available-non-conformance-column-width - @additional-width,
        @non-conformance-column-count,
        LeagueTable--NonConformanceCell
    );
    .conformanceColumnWidthCalc(
        @available-conformance-column-width + @additional-width,
        5,
        LeagueTable--ConformanceCell
    );
}

@media print {
    .LeagueTablePrint {
        border-collapse: collapse;
        border: none;
    }
    .LeagueTablePrint__Thead {
        height: 80pt;
    }
    .LeagueTablePrint__Tfoot__Tr {
        font-weight: 700;
    }
}
