.DropHoldingState {
    padding: @spacing-sm;
    height: 156px;
    display: flex;
    flex-direction: column;

    &__Container {
        display: flex;
        flex: 1 1 100%;
        flex-direction: column;
        background: @color-maxmine-primary-lightest;
        border-radius: 4px;
        padding: @spacing-sm;
        transition: opacity, background-color 0.3s linear;

        &.HoldingState--Disabled {
            background-color: @color-background-dark;
            opacity: 0.6;
        }
    }

    &__TitleContainer {
        display: flex;
        justify-content: space-between;
    }

    &__Content {
        display: flex;
        flex: 1 1 100%;
    }

    &__ContentLeft {
        display: flex;
        flex: 1 1 33%;
        flex-direction: column;
        padding: @spacing-sm;

        &__Content {
            display: flex;
            flex: 1 1 100%;
            align-items: center;
            justify-content: flex-start;
        }

        & .DropHoldingState__CardContainer {
            flex: 0 0 50%;
            justify-content: center;
            display: flex;
        }
    }

    &__ContentRight {
        background: lightgrey;
        display: flex;
        flex: 1 1 66%;
        flex-direction: column;
        padding: @spacing-sm;

        &__Content {
            display: flex;
            flex: 1 1 100%;
            align-items: center;
            justify-content: flex-start;
        }

        & .DropHoldingState__CardContainer {
            flex: 0 0 25%;
            justify-content: center;
            display: flex;
        }
    }

    // Prevent cluster badge from getting cut off
    & .Equipment__Card__Cluster {
        z-index: 1;
    }
}
