.Legend__Box__Polygons {
    display: flex;
    flex-direction: row;

    &__Container {
        padding: 10px;
        &__Title {
            text-align: left;
            margin-bottom: 5px;
            font-weight: bold;
            font-size: 90%;
        }
        &__Scale {
            ul {
                margin: 0;
                margin-bottom: 5px;
                padding: 0;
                float: left;
                list-style: none;
            }
        }
    }
}

.Legend__Box__Item {
    li {
        font-size: 80%;
        list-style: none;
        margin-left: 0;
        line-height: 18px;
        margin-bottom: 5px;
        span {
            display: block;
            float: left;
            height: 16px;
            width: 30px;
            margin-right: 5px;
            margin-left: 0;
            border: 1px solid #999;
        }
    }
}
