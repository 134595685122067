.LoadsInRangeDetail {
    &__TableButtonsContainer {
        display: flex;
        justify-content: flex-start;
    }

    &__TableSelectHint {
        padding-left: 1rem;
        font-size: 0.8rem;
        flex: 1 0 0%;
    }

    &__FixReactTableFooterPadding {
        padding: 7px 5px 7px 5px;
    }
}

.LoadingTimeDetailPage {
    & .ReactTable .rt-td {
        white-space: initial;
        text-overflow: unset;
    }
}
