.ProductivityCharts {
    // There exists some table css that messes with our SynchronisedChart components
    // This is to override that behaviour
    & table tbody tr td {
        line-height: unset;
        padding: unset;
        font-size: 11px;
        text-align: right;
    }
}
