.FeatureMessage {
    padding: @spacing-sm;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: @alert-warning-bg-color;
    border: 1px solid @alert-warning-border-color;
}

.FeatureMessage.RedBanner {
    background-color: @color-conformance-3-low;
}

.FeatureMessageBody {
    text-align: center;
    width: 100%;
    a {
        font-weight: @font-weight-bolder;
    }
}
