.Overlay {
    display: flex;
    flex-direction: column;
    height: 30px;
    width: 70px;

    & .ant-statistic-title {
        margin-bottom: -6px !important;
    }

    & .ant-card-body {
        padding: 0;
    }
}
