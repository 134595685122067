.MarkerPin {
    position: relative;

    // Make the icons a bit bigger
    transform: scale(1.3);
    top: -7px;
    cursor: default;
    &__Icon {
        position: absolute;
        height: 27px;
        width: 27px;
        top: 2px;
        left: 2px;
    }

    &__Status {
        position: absolute;
        height: 24px;
        width: 24px;
        top: -16px;
        left: 4px;
        transform: scale(1.3);
    }

    &__Label {
        background-color: fade(@color-background-white, 70%);
        border: none;
        box-shadow: none;
        outline: none;
        top: -9px;
        left: 38px;
        &.hovered {
            background-color: @color-background-white;

            &.leaflet-tooltip-left::before {
                border-left-color: @color-background-white;
            }

            &.leaflet-tooltip-right::before {
                border-right-color: @color-background-white;
            }
        }

        &.leaflet-tooltip-left::before {
            border-left-color: fade(@color-background-white, 70%);
        }

        &.leaflet-tooltip-right::before {
            border-right-color: fade(@color-background-white, 70%);
        }
    }
}
