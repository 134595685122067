.DispatchTable__HaulRow__tr .NodeStatusIcon__IconContainer {
    top: 0;
}

.NodeStatusIcon {
    height: 100%;
    position: relative;

    & > .DispatchTable__HaulRow {
        // Padding to make sure the cluster icon and the Node Status don't overlap on the Haul Row
        padding-right: 30px;
    }
}

.NodeStatusIcon__IconContainer {
    position: absolute;
    top: -7px;
    right: -13px;
    width: 35px;
    cursor: default;
}
