.DriverOnKeyOnPlot {
    &__TooltipContainer {
        font-size: 1rem;
        display: flex;
        flex-direction: column;
        font-size: 1rem;
        background: @color-background-white;
        padding: @spacing-sm;
        width: 400px;
        white-space: normal;
        border: 1px solid @color-background-dark;
    }

    &__TooltipContainer--Alert {
        background: rgb(235, 167, 147);
    }

    &__TooltipTitle {
        font-weight: @font-weight-bolder;
        text-align: center;
    }

    &__TooltipRow {
        display: flex;
        justify-content: space-between;

        &__Label {
            margin-right: @spacing-lg;
        }

        &__Label--Alert {
            margin-bottom: @spacing-sm;
        }
    }

    .highcharts-axis-labels.highcharts-xaxis-labels {
        font-size: 1rem;
    }

    .FalconChart__series--Equipment {
        .color-series-override(@color-density-double-8-1);
        stroke: @color-density-double-8-1;

        .highcharts-point {
            .color-series-override(@color-density-double-8-1);
            stroke: @color-density-double-8-1;
        }
    }

    .FalconChart__series--Operator {
        .color-series-override(@color-density-double-8-7);
        stroke: @color-density-double-8-7;

        .highcharts-point {
            .color-series-override(@color-density-double-8-7);
            stroke: @color-density-double-8-7;
        }
    }

    .FalconChart__series--UnknownEquipment {
        .color-series-override(@color-maxmine-not-expected);
        stroke: @color-maxmine-not-expected;

        .highcharts-point {
            .color-series-override(@color-maxmine-not-expected);
            stroke: @color-maxmine-not-expected;
        }
    }

    .FalconChart__series--AssumedOperator {
        .color-series-override(@color-display-7-5);
        stroke: @color-display-7-5;

        .highcharts-point {
            .color-series-override(@color-display-7-5);
            stroke: @color-display-7-5;
        }
    }

    .FalconChart__series--Alert {
        fill: @color-conformance-3-low;
        fill-opacity: 0.5;
        stroke: @color-conformance-3-low;
        stroke-width: 3;

        .highcharts-point {
            fill: @color-conformance-3-low;
            fill-opacity: 0.5;
            stroke: @color-conformance-3-low;
            stroke-width: 3;
        }
    }

    .Legend__Row--4 {
        // Make the shape container slightly larger to
        // allow for the triangle stroke to not get clipped
        svg {
            height: 27px;
        }
    }

    // Avoid the line and fills created by the svg tooltip
    .highcharts-tooltip-box {
        stroke: none;
        fill-opacity: 0;
    }
}
