.LoadingEventSymbologySymbol__IconWrapper {
    display: inline-flex;
    margin: 0 0.5rem 0 0.5rem;
}

.LoadingEventSymbologySymbol__Icon--GoodJob {
    color: green;
}

.LoadingEventSymbologySymbol__Icon--End {
    align-self: flex-end;
}
