.SpreadsheetsActiveDownloads__Row {
    margin: 0 0 1rem 0;
    background: whitesmoke;
    border: 1px solid lightgrey;
    padding: 1rem;
}

.SpreadsheetsActiveDownloads__Icon {
    font-size: 1.5rem;
    margin-right: 1rem;
}

.SpreadsheetsActiveDownloads__IconClose {
    font-size: 1.5rem;
    margin-left: 1rem;
    color: red;
    cursor: pointer;
}
