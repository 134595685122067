// These styles are to align the datepicker nicely with the rest of
// the filter controls
.DatepickerWithInput {
    & .DatePicker__Label {
        height: 22px;

        & button {
            border: 0;
            height: 0;
        }
    }

    & .DatePicker__Input {
        // Hard coded height to align with other input box
        height: 35.5px;
    }
}
