.Layout__Page {
    & .ControlRoom__HoldingStatesContainer {
        background: @color-maxmine-primary-base;
        border-left: 3px solid @color-background-white;
        padding: 0;
        // Add a max size so the carousel doesn't flicker when page is resized
        height: 200px;
        overflow: hidden;
    }

    & .ControlRoom__RestOfTrucksContainer {
        background: darken(@color-background-dark, 10%);
        border-left: @dispatch-border-size solid white;
        padding: @spacing-sm;
    }
}
