@media print {
    .LeagueTableConformanceCell {
        // Giving the td a height even though it's ignored so that width/height 100% will work
        height: 1px;
        padding: 0;
    }
    .LeagueTableConformanceCell__Wrapper {
        height: 100%;
        width: 100%;
        position: relative;
    }
    .LeagueTableConformanceCell__BackgroundImg {
        display: block;
        height: 100%;
        width: 100%;
    }
    .LeagueTableConformanceCell__Value {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
