.ProductivityEventTable {
    width: 100%;
    th,
    td {
        width: 40%;
    }
    th:first-child,
    td:first-child {
        width: 20%;
    }
}

.ProductivityEventTable--2Columns {
    width: 100%;
    th,
    td {
        width: 50%;
    }
}
