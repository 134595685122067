.ant-carousel .slick-next.Carousel__NextArrow {
    color: white;
    font-size: 2rem;
    display: block;
}

.ant-carousel .slick-prev.Carousel__PrevArrow {
    color: white;
    font-size: 2rem;
    display: block;
    position: absolute;
    left: -35px;
}

.Carousel__Container {
    margin-right: @spacing-xl;
    margin-left: @spacing-xl;
}
