.FullscreenBanner {
    background-color: transparent;
    height: 2em;
    position: absolute;
    z-index: 1000;
    width: 100%;

    button {
        border-radius: 20%;
        border: 1px solid;
        color: rgba(0, 0, 0, 0.25);
        top: 30px;
        left: 50%;
        transform: translate(-50%, -50%);

        &:hover {
            color: rgba(0, 0, 0, 0.65);
        }
    }
}
