.userListItem {
    & {
        background-color: #f8f8f8;
        margin: @spacing-md @spacing-sm;
        padding: @spacing-md;
    }

    & .profilePicture {
        text-align: center;
    }
    & .profilePicture img {
        width: 80px;
        height: 75px;
        object-fit: cover;
        border-radius: 5%;
    }
    & .deleteUserButton {
        padding: 0 27px;
        color: @status-color-red;
        margin-right: @spacing-sm;
        margin-top: @spacing-sm;
    }
    & .editUserButton {
        padding: 0 35px;
        color: @color-maxmine-primary-base;
        margin-right: @spacing-sm;
        margin-top: @spacing-sm;
    }
    & .assignGroupButton {
        padding: 0 19px;
        color: @color-maxmine-primary-base;
        margin-right: @spacing-sm;
        margin-top: @spacing-sm;
    }
    & .impersonateUserButton {
        padding: 0 19px;
        color: @color-maxmine-primary-base;
        margin-right: @spacing-sm;
        margin-top: @spacing-sm;
    }
    & .userInfoPrimary {
        word-wrap: break-word;
    }
    & .userInfoSecondary {
        word-wrap: break-word;
    }

    &.maxmineUser {
        // Banner styles
        position: relative;

        &:before {
            content: 'MaxMine User'; // Text for the banner
            background-color: @color-maxmine-primary-base; // Banner background color
            color: @color-text-light;
            padding: 8px; // Adjust as needed
            display: block;
            position: absolute;
            top: 0;
            right: 0; // Align to the right
            text-align: center;
        }
    }
}

@media only screen and (max-width: 600px) {
    .userInfoPrimary {
        text-align: center;
        margin-top: @spacing-sm;
    }
    .userInfoSecondary {
        text-align: center;
        margin-bottom: @spacing-sm;
    }
}
