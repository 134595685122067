.DatePicker {
    display: flex;
    flex-direction: column;

    &__Input {
        width: 100%;
    }

    &__Label {
        display: flex;
        justify-content: space-between;

        // The same height as the Button component
        height: 32px;

        // Align the label vertically in the middle
        & span {
            display: flex;
            align-items: center;
        }

        // Remove padding and height from the switcher button link
        & button {
            padding: 0;
        }
    }
}

.MultiDatePicker {
    position: relative;
    &__Button {
        // Move the button down the end
        padding: 0;
        position: absolute;
        right: 0;
    }
}
