.MapViewMapLegend {
    position: absolute;
    left: calc(100% - 170px);
    top: 60px;
    background-color: white;
    padding: 5px;
    width: 140px;
}

.MapViewMapLegend__Row {
    display: flex;
    justify-content: center;
    align-items: center;
}

.MapViewMapLegend__IconWrapper {
    width: 1rem;
    height: 1rem;
    text-align: right;
    display: flex;
    justify-content: center;
}

.MapViewMapLegend__Label {
    margin-left: 0.5rem;
}

.MapViewMapLegend__AlertLevel3 {
    font-size: 1rem;
    color: #d9534f;
}

.MapViewMapLegend__AlertLevel2 {
    font-size: 0.7rem;
    color: #f57f17;
}

.MapViewMapLegend__AlertLevel1 {
    font-size: 0.5rem;
    color: #ffd600;
}
