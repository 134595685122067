.UnsupportedFeatureMessage {
    text-align: center;

    a {
        font-weight: @font-weight-bolder;
    }
}

.UnsupportedFeatureMessage__Modal {
    table {
        width: 100%;
        border: 1px solid @color-background-light;

        & tr:nth-child(even) {
            background: @color-background-light;
        }
    }
    img {
        max-height: 100%;
        max-width: 100%;
    }
}

.modal-dialog.UnsupportedFeatureMessage__Modal {
    @media (min-width: 768px) {
        width: 80%;
    }
}
