@critical-params-group-width: 110px;

.CriticalParams__PivotGrid {
    & .PivotGridContainer {
        padding-left: @critical-params-group-width;
    }

    & .GridLabels__Outer {
        transform: translate(-@critical-params-group-width, 0);
        position: absolute;
        width: @critical-params-group-width;
    }

    & .AlertLevel__Label {
        width: @critical-params-group-width;
    }

    // Right align the Parameter names column
    & .PivotGrid__LeftSideGridContainer__LeftSideGrid__Cell {
        align-items: flex-end;
        padding-right: 10px;
    }

    & .CriticalParams__PivotGrid__Cell {
        height: 100%;
        width: 100%;
        &--Available {
            background: @color-conformance-3-high;
        }

        &--Missing {
            background: @color-conformance-3-low;
        }

        &--Suspect {
            background: @color-conformance-3-medium;
        }
        &--EquipmentOff {
            background: @color-maxmine-equipment-off;
        }
        &--NotExpected {
            background: @color-maxmine-not-expected;
        }
    }
}

.CriticalParams__Legend .Legend {
    padding-top: @spacing-md;

    &__Item--0 {
        fill: @color-conformance-3-high;
    }
    &__Item--1 {
        fill: @color-conformance-3-medium;
    }
    &__Item--2 {
        fill: @color-conformance-3-low;
    }
    &__Item--3 {
        fill: @color-maxmine-equipment-off;
    }
    &__Item--4 {
        fill: @color-maxmine-not-expected;
    }
}
