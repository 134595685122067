.LoadUnitFilters {
    &__ShowHide {
        text-align: right;
        // Make the same height as the select dropdowns
        // so it vertically centers nicely
        height: 73px;
        width: auto;
        float: right;
    }
}
