.LineChart__series--dashed {
    &.highcharts-series {
        // Style the optimal line as a dash
        .dashed-line-mixin();
        // Lighten the optimal line to contrast it a bit with the other line
        stroke-opacity: 0.55;
    }

    // Style the optimal range area lighter than usual
    & .highcharts-area {
        fill-opacity: 0.15;
    }
}
