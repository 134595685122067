@edit-holding-state-max-width: 400px;

.EditHoldingStatesList {
    display: flex;
    flex-wrap: wrap;
}

.EditHoldingStatesList__Container {
    flex: 0 1 @edit-holding-state-max-width;
    margin-bottom: @spacing-md;
    margin-left: @spacing-sm;
    margin-right: @spacing-sm;

    & .TruckHoldingState {
        // Lighten the background a bit so red errors displays nicely
        background: lighten(@color-maxmine-primary-lightest, 10%);
    }
}
