.HaulRoadConditionLastShift {
    display: flex;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    // NOTE: this needs to match the margin-left in HaulRoadConditionChart so they are aligned together
    margin-left: 60px;
}

.HaulRoadConditionLastShift__Title {
    width: 100%;
    font-weight: 600;
    align-self: flex-start;
}

.HaulRoadConditionLastShift__Row {
    display: flex;
    flex-wrap: nowrap;
}

.HaulRoadConditionLastShift__Cell {
    width: 120px;
    align-self: flex-end;
    margin: 0 10px 0 10px;
}

.HaulRoadConditionLastShift__Conformance {
    padding: 0.75rem;
    border: 2px solid;
    color: black;
    font-size: 1.25rem;
    margin-top: 0.25rem;
}

.HaulRoadConditionLastShift__Conformance--Extreme {
    background-color: rgba(@surface-roughness-color-highest, 0.75);
    border-color: @surface-roughness-color-highest;
}

.HaulRoadConditionLastShift__Conformance--Smooth {
    background-color: rgba(@surface-roughness-color-lowest, 0.75);
    border-color: @surface-roughness-color-lowest;
}

.HaulRoadConditionLastShift__KpiDirectionContainer {
    display: flex;
    flex-direction: column;
    font-size: 1.25rem;
    justify-content: flex-end;
}
