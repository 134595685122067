.EditUserPage {
    margin-top: @spacing-lg;

    & .avatar-uploader > .ant-upload {
        width: 108px;
    }
    & .avatar-uploader img {
        width: 80px;
        height: 75px;
        object-fit: cover;
        border-radius: 10%;
    }
    & .ant-upload-text {
        text-align: center;
    }

    & .changePassword {
        margin-bottom: 20px;
    }

    & .SelectCrew {
        width: '100%';
    }
}
