.LoadUnitSymbologyLegend__Container {
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    padding: 1rem;
}

.LoadUnitSymbologyLegend {
    display: flex;
}

.LoadUnitSymbologyLegend__LeftColumn {
    display: flex;
    flex-direction: column;
    flex: 1 1 30%;
}

.LoadUnitSymbologyLegend__LeftColumn--Border {
    border-right: 1px solid black;
    margin-right: 1rem;
}

.LoadUnitSymbologyLegend__RightColumn {
    display: flex;
    flex-direction: column;
    flex: 1 1 70%;
}

.LoadUnitSymbologyLegend__RightColumnInnerContainer {
    display: flex;
    flex-direction: row;
}

.LoadUnitSymbologyLegend__ImproveScores {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.LoadUnitSymbologyLegend__ImproveScoresRowContainer {
    flex: 0 0 50%;
}

.LoadUnitSymbologyLegend__Title {
    flex-direction: row;
    font-weight: 700;
    text-decoration: underline;
    text-align: center;
}

.LoadUnitSymbologyLegend__Title--Minor {
    font-weight: initial;
    font-style: italic;
    text-decoration: none;
    font-size: 0.825em;
    padding: 1rem;
    align-self: center;
}

.LoadUnitSymbologyLegend__Conformance {
    display: flex;
    justify-content: center;
    margin: 0 1rem 0 1rem;
}

.LoadUnitSymbologyLegend__Row {
    display: flex;
    align-items: center;
    padding: 1rem 0 1rem 0;
}

.LoadUnitSymbologyLegend__Icon {
    flex: 0 0 20%;
}

.LoadUnitSymbologyLegend__Label {
    text-transform: capitalize;
    flex: 0 0 80%;
    text-align: left;
}

.LoadUnitSymbologyLegend__Annotation {
    text-align: center;
}
