.TyresAndSafetyTable {
    width: 100%;
    td,
    th {
        width: 12.5%;
    }

    td:nth-child(5),
    td:nth-child(6),
    td:nth-child(7),
    th:nth-child(5),
    th:nth-child(6),
    th:nth-child(7) {
        width: 15%;
    }
}
