.Navigation {
    // Show scrollbar when necessary and hide the horizontal bar
    overflow-y: auto;
    overflow-x: hidden;

    &__Menu {
        background-color: @color-maxmine-primary-base;
        padding-top: @spacing-md;
    }

    // Ant overiddes
    & .ant-menu {
        // Remove the white background so the maxmine color shows through
        &-sub {
            background-color: @color-maxmine-primary-darkest;

            padding-top: 8px;
            padding-bottom: 8px;
        }

        & .ant-menu-submenu-open > ul {
            display: grid;
            gap: 8px;
        }

        // Remove white border to the right of the menu
        &-inline {
            border-right: none;
        }

        // Remove color transitions and set smaller item spacings
        &-item,
        &-item:not(:last-child),
        .ant-menu-sub.ant-menu-inline > .ant-menu-item,
        &-submenu-title {
            height: 34px;
            transition: color 0s;
            margin-top: 0px;
            margin-bottom: 0px;
        }

        & .ant-menu-submenu {
            margin-bottom: 8px;
        }

        & .ant-menu-item {
            margin-bottom: 8px;
            height: 40px;
        }

        // Set the unhovered text color to light grey
        &-item a,
        &-item-group-title,
        &-submenu-title {
            color: @color-text-white;
        }

        & .ant-menu-item,
        & .ant-menu-submenu-title {
            padding-left: @spacing-lg !important; // override ant menu default
        }

        & .ant-menu-submenu .ant-menu-item {
            padding-left: @spacing-xl !important; // override ant menu default
        }

        // Set bold titles
        &-item a {
            .font-menu-item();
        }
        &-submenu-title {
            .font-menu-header();
        }

        // The arrow stylings are buried under a hefty bunch of ant CSS
        // Override the color here
        &-submenu-arrow:before,
        &-submenu-arrow:after {
            // TODO: Couldn't get this to work, remove the !important
            background: @color-text-light !important;
        }

        // Set the hover to have an instant transition
        // Remove the background hover
        // Set the text color to white
        &-item:hover,
        &-item:hover a,
        &-item-active,
        &-item-active a,
        &-submenu-active,
        &-submenu-title:hover {
            color: @color-maxmine-secondary-gradient-end;
            background: unset;
            transition: color 0s;
        }

        // Add a transparent underline to each item
        // This'll be transitioned to orange when selected
        & .ant-menu-item {
            font-weight: 500 !important;

            & a {
                height: 34px;
                line-height: 34px;
                & span {
                    height: 34px;
                    line-height: 34px;
                }
            }
        }

        & .ant-menu-item-selected {
            // Remove the selected background color
            // Set the selected item text to darkest
            background-color: unset;

            & a {
                position: relative;
                display: block;

                &:before {
                    content: '';
                    position: absolute;
                    top: 0px;
                    left: -8px;
                    right: 0px;
                    bottom: 0px;
                    border-radius: 5px;
                    background-color: @color-maxmine-primary-lightest;
                }

                & span {
                    display: block;
                    position: relative;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }
            }

            &:after {
                // Remove the default selection border
                display: none;

                // Uncomment this for an orange arrow
                /*left: 0;*/
                /*top: 7px;*/
                /*bottom: 11px;*/
                /*border-left: 11px solid @color-maxmine-secondary-base;*/
                /*border-right: 11px solid transparent;*/
                /*border-top: 11px solid transparent;*/
                /*border-bottom: 11px solid transparent;*/
            }
        }

        & .ant-menu-submenu .ant-menu-item-selected {
            & a {
                &:before {
                    left: -16px;
                }
            }
        }
    }
}

.Navigation__MenuButton {
    background-color: @color-maxmine-primary-dark;
    // Remove rounded edges and border shadows
    border-radius: @border-radius-base;
    border: 0px;
    box-shadow: none;
    // Height to fill to the parent
    height: inherit;
    // Scale with the width of the header
    width: 7%;
    min-width: 45px;
    // Make the icon bigger
    font-size: 25px;
    &:active,
    &:focus,
    &:hover {
        // Use our own color for hover / click
        background-color: @color-maxmine-primary-light;
    }
}

.Navigation__UserButton {
    background-color: @color-maxmine-primary-dark;
    // Remove rounded edges and border shadows
    border-radius: @border-radius-base;
    border: 0px;
    box-shadow: none;
    // Height to fill to the parent
    height: 100%;

    &:active,
    &:focus,
    &:hover {
        // Use our own color for hover / click
        background-color: @color-maxmine-primary-light;
    }
}

.Navigation__HistoryStatusButton {
    // This helps it display better in mobile mode
    display: flex;

    & > label {
        height: @layout-subheader-height;
        line-height: @layout-subheader-height;
        padding: 0 @spacing-lg;
    }
    // Ant styling overrides
    &.ant-radio-group-solid .ant-radio-button-wrapper {
        &.ant-radio-button-wrapper-checked {
            // Selected button styles
            background-color: @color-maxmine-primary-lightest;
            color: @color-text-light;

            // Remove shadows and outlines
            box-shadow: none;
            &:focus-within {
                outline: none;
            }
        }
        // Remove divider between the buttons
        &::before {
            display: none;
        }
        // Not selected button styles
        background: @color-background-dark;
        color: lighten(@color-text-dark, 30%);
        border: none;
    }
}
