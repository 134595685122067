/*
This file is deliberately being imported as a CSS file in the component so that it will be
part of the main css bundle. This is so the initial page load has some basic styles.
*/

.AppInit {
    background-color: white;
}

.AppInit__LoadingSpinner {
    padding: 20px;
    position: fixed;
    z-index: 2000000000;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    font-family: 'Forno MX', serif;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
}

.AppInit__LoadingSpinnerIcon {
    font-size: 30px;
}
