.TyresAndSafetyTopClustersTable {
    // Override the default table stylings
    &.Table--Default {
        // Fit the table to the page content
        width: 100%;
    }

    &__CornerHeader {
        width: 30%;
    }

    &__SpeedHeader {
        border-bottom: 1px solid @color-border-dim;
        text-align: center;
    }
}
