.LoadingTimeDetailPage {
    & .FalconChart__chart {
        // There exists some (foundation?) table css that messes with the tooltip styling
        // This is to override that behaviour
        & .highcharts-tooltip {
            &,
            & table,
            & tr,
            & td {
                background: unset;
                margin-bottom: unset;
                padding: unset;
                font-size: 12px;
                color: rgba(0, 0, 0, 0.65);
            }
        }
    }
    & .LoadingTimeDetailChartManager {
        & .BinSize {
            width: 65px;
            margin: 0 0 0 auto;
        }
    }
}
