/* Leave nothing to the CSS stylesheet order! */
.whenPrintingShow {
    display: none !important;
}
.whenPrintingHide {
    display: block !important;
}

@media print {
    .whenPrintingShow {
        display: block !important;
    }
    .whenPrintingHide {
        display: none !important;
    }
}
