.AppLanding {
    display: flex;
    justify-content: center;
    align-items: center;
}

.AppLanding__PanelContainer {
    display: inline-block;
}

.AppLanding__Panel {
    @include panel;
    max-width: 600px;
}

.AppLanding__Email,
.AppLanding__Footer,
.AppLanding__Content {
    margin-top: 1rem;
}

@media screen and (min-width: 1000px) {
    .AppLanding {
        margin-top: 20vh;
    }
}
