.DragTruck__Container {
    cursor: move;
}

.Equipment__Card__Cluster {
    background: white;
    color: black;
}

.Equipment__Card__Footer {
    font-weight: 600;
}
